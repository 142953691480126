import React from "react";
import _ from "lodash";

import { A4Page } from "~/components/print";
import { formats } from "~/helper";
import RenderFooter from "~/pages/inspection/reports/blend/footer";
import RenderSubSection from "~/pages/inspection/reports/blend/sub-section";
import {
  Container,
  Field,
  Line,
  Sign
} from "~/pages/inspection/reports/blend/helper";

function SignPage({ model = {}, getPage = _.noop }) {
  const identify = _.get(model, "customer.identify");
  const name = _.get(model, "customer.name");
  const board = _.get(model, "board");

  return (
    <A4Page>
      <Container>
        <RenderSubSection text="IMPORTANTE" />
        <Line>
          <Field>
            <em>
              Este laudo não tem caráter pericial e, portanto, não substitui a
              perícia criminalistica oficial. As informações constantes neste
              laudo têm validade apenas para o exato momento de sua realização,
              limitando-se a verificar visualmente a identificação, a estrutura
              do veículo, a conformidade da pintura com os padrões originais, e
              o funcionamento aparente de acessórios, com a indicação das não
              conformidades destes itens diagnosticados pelo vistoriador.{" "}
              <strong>
                Este laudo não verifica sinais de alagamento no veículo.
              </strong>
            </em>
          </Field>
        </Line>

        <br />
        <br />
        <br />

        <Sign>
          <div className="underline">
            ____________________________________________________________________________________
          </div>
          <strong>Cliente: {formats.cnpj_cpf(identify) + " - " + name}</strong>
          <strong>Placa: {formats.board(board)}</strong>
        </Sign>

        <RenderFooter model={model} page={getPage()} />
      </Container>
    </A4Page>
  );
}

export default SignPage;
