import React, { useState, useEffect, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FloatingActionButton from "~/components/floatbutton";
import Header from "~/components/header";
import Spinner from "~/components/spinner";
import { Search } from "../../components/form";
import {
  Container,
  Content,
  ActionsBar
} from "./styles";
import { MdAdd, MdEdit, MdPersonAdd } from "react-icons/md";
import useModal from "~/hooks/use-modal";
import UserForm from "./form";
import { Creators as UserActions } from "~/store/modules/users";
import { getModulesView } from "~/constants";
import { CellContainer, Square } from "../inspection/list/styles";
import { formats } from "~/helper";
import { primary } from '~/components/mixins/color';
import DataTable from "react-data-table-component";

function User() {
  const dispatch = useDispatch();
  const { isOpen, openModal, closeModal } = useModal();
  const [user, setUser] = useState({});
  const [title, setTitle] = useState();
  const profile = useSelector(state => _.get(state, "profile.profile", {}));
  const state = useSelector(state => state.users);

  const columns = useMemo(
    () => [
      {
        name: "# Nome",
        selector: "name",
        cell: row => {
          return (
            <CellContainer>
              <Square inUpdate={!row.active} />
              <strong>{row.name}</strong>
            </CellContainer>
          );
        }
      },
      {
        name: "E-mail",
        selector: "email",
        cell: row => (
          <strong className="remove-point-events-datatable">
            {_.get(row, "email") || ""}
          </strong>
        )
      },
      {
        name: "Data",
        selector: "createdAt",
        hide: "md",
        cell: row =>
          formats.dateTimeZone(_.get(row, "createdAt"), "dd/MM/yyyy HH:mm:ss")
      }
    ],
    []
  );

  useEffect(() => {
    const reset = () => dispatch(UserActions.resetStateUser());
    dispatch(UserActions.listUserRequest());
    return () => {
      reset();
    };
  }, [dispatch]);

  function handleActions(evt, data, act) {
    evt.preventDefault();
    setUser(data);
    configTitle(act);
    openModal();
  }

  const handlePageChange = page => {
    dispatch(UserActions.listUserRequest(page));
  };

  const searching = term => {
    dispatch(UserActions.listUserRequest(0, term));
  };

  function handleOnSubmit(data, actions) {
    actions.closeModal = () => {
      setUser({});
      closeModal();
    };
    dispatch(UserActions.createOrUpdateUser(data, actions));
  }

  function configTitle(act) {
    const text = act === "add" ? "Adicionar" : "Editar";
    setTitle(
      <>
        {act === "add" ? <MdPersonAdd /> : <MdEdit />}
        &nbsp;&nbsp;<span>{text} usuário</span>
      </>
    );
  }

  function ButtonAdd() {
    return (
      <FloatingActionButton
        background={primary.hex()}
        onClick={e => handleActions(e, { active: true }, "add")}
      >
        <MdAdd />
      </FloatingActionButton>
    );
  }

  const UserHeader = memo(({ loading, count }) => {
    return (
      <Header>
        <h3>Usuários ({count})</h3>
        <ActionsBar>
          <Spinner visible={loading} />
          <Search onSearch={searching} />
        </ActionsBar>
      </Header>
    );
  });

  return (
    <Container>
      <UserHeader loading={state.loading} count={state.count || 0} />
      <ButtonAdd />
      {isOpen && (
        <UserForm
          title={title}
          data={user}
          isOpen={isOpen}
          closeModal={closeModal}
          handleOnSubmit={handleOnSubmit}
          modulesView={getModulesView(_.get(profile, "is_customer", false))}
        />
      )}
      <Content visible={_.size(state.data) > 0}>
        <DataTable
          theme="segvis"
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          dense
          persistTableHead
          showRowHover
          overflowY
          columns={columns}
          data={_.get(state, "data", [])}
          noDataComponent={
            <p>
              <br />
              <strong>Nenhum registro encontrado!</strong>
              <br />
              <br />
            </p>
          }
          onRowClicked={(row, e) => handleActions(e, row, "edit")}
          page={_.get(state, 'page') || 0}
          paginationPerPage={15}
          pagination
          paginationServer
          paginationTotalRows={_.get(state, 'count') || 0}
          onChangePage={handlePageChange}
          paginationComponentOptions={{
            noRowsPerPage: true,
            rangeSeparatorText: "de"
          }}
        />
      </Content>
    </Container>
  );
}

export default User;
