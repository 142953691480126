import isEmpty from "lodash/isEmpty";
import user from "~/assets/user.svg";
import {
  MdViewList,
  MdThumbDown,
  MdThumbUp,
} from "react-icons/md";
import {FaHandsHelping} from "react-icons/fa";

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:7000';

// export const MODULES_VIEW = {
//     "V_INSPECTIONS": "Acompanhamento de vistorias",
//     "V_USERS": "Gerenciamento de usuários",
//     "V_CUSTOMERS": "Gerenciamento de clientes",
//     // "V_SEARCH_INSPECTIONS": "Pesquisa de vistorias",
//     // "V_BILLINGS": "Histórico de pagamentos",
// }

export const TYPE_SEARCH = {
  'full': 'Completa',
  'bin': 'Estadual'
};

export const getModulesView = (isCustomer) => {
    const views = {
    "0": "Acompanhamento de vistorias",
    "1": "Gerenciamento de usuários",
    "2": "Gerenciamento de clientes",
    "3": "Pesquisa de vistorias",
    // "4": "Histórico de pagamentos",
    "5": "Relatório de fechamento mensal",
    };
    if (isCustomer) {
        delete views["2"];
        delete views["5"];
    }
    return views;
}

export const serviceTypes = {
    'survey': {
      label: "Vistoria",
      icon: MdViewList,
    },
    'disapproved': {
      label: "Não conforme",
      icon: MdThumbDown,
    },
    'approved': {
      label: "Aprovado",
      icon: MdThumbUp,
    },
    'restrictions': {
      label: "Aprovado c/Apontamentos",
      icon: FaHandsHelping,
    },
  };

export const UFS = {
    'AC': 'Acre',
    'AL': 'Alagoas',
    'AP': 'Amapá',
    'AM': 'Amazonas',
    'BA': 'Bahia',
    'CE': 'Ceará',
    'DF': 'Distrito Federal',
    'ES': 'Espírito Santo',
    'GO': 'Goiás',
    'MA': 'Maranhão',
    'MT': 'Mato Grosso',
    'MS': 'Mato Grosso do Sul',
    'MG': 'Minas Gerais',
    'PA': 'Pará',
    'PB': 'Paraíba',
    'PR': 'Paraná',
    'PE': 'Pernambuco',
    'PI': 'Piauí',
    'RJ': 'Rio de Janeiro',
    'RN': 'Rio Grande do Norte',
    'RS': 'Rio Grande do Sul',
    'RO': 'Rondônia',
    'RR': 'Roraima',
    'SC': 'Santa Catarina',
    'SP': 'São Paulo',
    'SE': 'Sergipe',
    'TO': 'Tocantins',
    'EX': 'Exterior'
}

export const getAvatar = function(avatar) {
    if (isEmpty(avatar)) return null;
    return `${BASE_URL}/profiles/get-avatar?filename=${avatar}`;
}

export const getAvatarDefault = function() {
    return user;
}

export const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const CNPJ_MASK = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export const CPF_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export const PLACA_MASK = [/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, '-', /\d/, /[A-Za-z0-9]/, /\d/, /\d/];

export const PHONE_MASK = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const CELLPHONE_MASK = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const CEP_MASK = [ /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
