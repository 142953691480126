import styled from "styled-components";

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  & > div {
    margin-left: 10px;
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-height: ${props => props.maxHeight};
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-auto-rows: 90% 1fr;
  width: 100%;
  height: 100%;
  padding: 10px;

  > div {
    padding: ${props => props.padding || "10px"};
    background: #fff;
    border-radius: 2px;
    height: calc(100%);
    overflow: hidden;
  }
  
  .rdt_Pagination {
    justify-content: center;

    span {
      margin: 0;
    }
  }
  .rdt_TableBody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (max-width: 599px) {
    .rdt_Pagination {
      justify-content: flex-start;

      span {
        margin: 0 10px;
      }
    }
  }
`;

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Square = styled.div`
  background: ${props => (props.inUpdate ? "#e6467f" : "#53d449")};
  left: 0px;
  position: absolute;
  width: 3px;
  height: 100%;
`;
