import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import AsyncSelect from "react-select/async";
import { MdSchedule } from "react-icons/md";
import { Formik, ErrorMessage } from "formik";
import { MaskInput, Checkbox, InputGroup, Select } from "~/components/form";
import Modal from "~/components/modal";
import useWindowSize from "~/hooks/use-window-size";
import { FileImageCrop } from "~/components/form";
import { PLACA_MASK, TYPE_SEARCH } from "~/constants";
import { StyledForm } from "~/pages/inspection/kanban/styles";

const customStyles = {
  control: base => ({
    ...base,
    border: "1px solid #ccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #ccc"
    }
  })
};

const AddForm = props => {
  const windowSize = useWindowSize();
  const {
    customer,
    onSearchCustomer,
    isOpen,
    closeModal,
    handleCancelling,
    handleSubmit
  } = props;

  let selectCustomerValue;

  if (!isEmpty(customer)) {
    selectCustomerValue = {
      label: _.get(customer, "name"),
      value: customer
    };
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ customer }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialTouched={{
        validateServices: true
      }}
      validateOnMount={true}
    >
      {({
        errors,
        touched,
        handleReset,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <Modal
          width="420px"
          height={windowSize.height < 470 ? "95%" : "630px"}
          allowFullscreen={false}
          open={isOpen}
          hide={closeModal}
          title={
            <>
              <MdSchedule />
              &nbsp;&nbsp;<span>Nova Consulta</span>
            </>
          }
          positive={{
            text: isSubmitting ? "Aguarde..." : "Confirma",
            action: handleSubmit,
            disabled: _.size(errors) > 0 || isSubmitting
          }}
          negative={{
            text: "Cancela",
            action: () => handleReset() && handleCancelling(),
            disabled: isSubmitting
          }}
        >
          <StyledForm>
            <div className="line">
              <Checkbox name="hasStructure" label="Estrutural" />
              <Checkbox name="hasPaint" label="Pintura" />
            </div>
            <ErrorMessage
              name="validateServices"
              className="error"
              component="div"
            />

            <Select
              name="searchType"
              label="Consulta"
              options={{
                defaults: { value: "", label: "Sem consulta" },
                values: Object.keys(TYPE_SEARCH).map(key => ({
                  value: key,
                  label: TYPE_SEARCH[key]
                }))
              }}
            />

            <AsyncSelect
              className="select"
              autoFocus={true}
              isSearchable={true}
              isClearable={true}
              loadOptions={onSearchCustomer}
              defaultValue={selectCustomerValue}
              isDisabled={!isEmpty(selectCustomerValue)}
              onChange={opt => {
                setFieldValue("customer", _.get(opt, "value"));
              }}
              styles={customStyles}
              placeholder="Informe o cliente"
              noOptionsMessage={props => {
                return _.size(props.inputValue) === 0
                  ? "Digite parte do nome do cliente para efetuar a pesquisa!"
                  : `Não foi encontrado registros com o termo "${props.inputValue}"`;
              }}
            />

            <InputGroup type="text" name="salesman" label="Nome do vendedor" />

            <MaskInput
              className="input-mask"
              label="Placa"
              name="board"
              hasError={errors.board && touched.board}
              placeholder="___-____"
              mask={PLACA_MASK}
            />

            <FileImageCrop
              label="Foto do documento do veículo"
              name="file"
              aspect={0.7}
              height={"290px"}
            />
          </StyledForm>
        </Modal>
      )}
    </Formik>
  );
};

let schema = Yup.object().shape({
  customer: Yup.object()
    .shape({ id: Yup.string().required() })
    .required("Selecione o Cliente"),
  board: Yup.string().required("Informe a placa do veículo"),
  file: Yup.mixed().required("Foto do documento do veículo é necessária"),
  hasStructure: Yup.boolean(),
  hasPaint: Yup.boolean()
});

schema = schema.test("validateServices", null, obj => {
  if (obj.hasStructure || obj.hasPaint || obj.hasSearch) {
    return true;
  }
  return new Yup.ValidationError(
    "Selecione os serviços que serão executados",
    null,
    "validateServices"
  );
});

AddForm.propTypes = {
  onSearchCustomer: PropTypes.func.isRequired,
  handleCancelling: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

AddForm.defaultProps = {
  isOpen: false,
  handleCancelling: () => {}
};

export default AddForm;
