import React from "react";
import { Container } from "./styles";

function Forbidden() {
    return (
        <Container>
            <h1>403</h1>
            <p>Você não possui permissão para acessar essa página!</p>
        </Container>
    );
}

export default Forbidden;
