import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "~/services/api";
import { Creators as AuthActions, Types as AuthTypes } from "~/store/modules/auth";
import history from "~/services/history";
import { getAvatar } from "~/constants";
import { getError } from "~/helper";

export function* signIn({ data, actForm }) {
  try {
    const response = yield call(api.post, "auth/login", data);
    const { token, me } = response.data;
    me.avatar = getAvatar(me.avatar);
    yield put(AuthActions.signInSuccess(token, me));
  } catch (error) {
    if (actForm) {
      actForm.setSubmitting(false);
    }
    yield put(AuthActions.signFailure(error));
  }
}

export async function success({ token }) {
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
  history.push("/");
}

export function failure({ error }) {
  const msg = getError(error);
  toast.error(msg);
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function singOut() {
  history.push("/login");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_IN_SUCCESS, success),
  takeLatest(AuthTypes.SIGN_FAILURE, failure),
  takeLatest(AuthTypes.SIGN_OUT, singOut)
]);
