import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import Modal from "~/components/modal";
import { StyledForm, Inform } from './styles';
import { Checkbox, Select, MaskInput, InputGroup } from "../../components/form";
import Divider from "~/components/divider";
import useWindowSize from "~/hooks/use-window-size";
import Spinner from "~/components/spinner";
import { PHONE_MASK, CNPJ_MASK, CPF_MASK, CEP_MASK, UFS } from '~/constants';
import { onlyNumbers, formats } from "~/helper";
import _ from "lodash";

const RadioButton = ({ name, label, value, ...rest }) => (
  <>
    <label htmlFor={name} className="radio">
      <Field type="radio" id={name + '.' + value} name={name} value={value} {...rest} />
      {label}
    </label>
  </>
);

function CustomerForm({
  title,
  data={identify: '', notes: ''},
  isOpen,
  closeModal,
  handleOnSubmit,
  handleCancelling,
  searchCnpj,
  cnpjSearching
}) {
  const isJuridica = (value) => {
    return _.get(value, "identifyType", "company") === "company";
  }
  const windowSize = useWindowSize();

  const schema = Yup.object().shape({
    id: Yup.string(),
    identify: Yup.string().test(
      'len',
      'Informe um CNPJ/CPF válido',
      value => value && (value.toString().length === 11 || value.toString().length === 14)
    ),
    name: Yup.string().min(4, "Verifique se a 'razão social' da empresa está correta").required("Informar a 'razão social'"),
    adminUsername: Yup.string().when('id', {
      is: val => !val,
      then: Yup.string().required('Informe o nome do usuário')
    }),
    adminEmail: Yup.string().when('id', {
      is: val => !val,
      then: Yup.string().email("Informe um e-mail válido").required("Informe o e-mail do usuário")
    })
  });

  function OnChangeRadio(setFieldValue, value) {
    setFieldValue('identifyType', value);
    setFieldValue('identify', '');
    setFieldValue('name', '');
    setFieldValue('tradeName', '');
    setFieldValue('phone', '');
    setFieldValue('address', {});
    setFieldValue('extras', {});
    setFieldValue('notes', '');
  }

  return !isOpen ? null :
  (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={schema}
      initialValues={data}
      onSubmit={handleOnSubmit}
      initialTouched={{
        'permissions': true
      }}
    >
      {
        ({
          errors,
          isValid,
          status,
          touched,
          handleSubmit,
          handleReset,
          isSubmitting,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
            <Modal
                width="400px"
                height={windowSize.height < 470 ? "90%" : "85%"}
                allowFullscreen={true}
                open={isOpen}
                hide={closeModal}
                title={cnpjSearching ? (<><Spinner visible={cnpjSearching} /> consultando CNPJ... </>) : title}
                positive={{
                    text: isSubmitting ? "Aguarde..." : "Confirma",
                    action: handleSubmit,
                    disabled: !isValid || isSubmitting
                }}
                negative={{
                    text: "Cancela",
                    action: () => handleReset() && handleCancelling(),
                    disabled: isSubmitting
                }}
            >
            <StyledForm>
                <Checkbox
                    name="active"
                    label="Ativo"
                    disabled={cnpjSearching}
                />

                <div className="inline">
                  <RadioButton
                    disabled={cnpjSearching || values.id}
                    name="identifyType"
                    label="Pessoa Jurídica"
                    value="company"
                    onChange={(e) => OnChangeRadio(setFieldValue, e.target.value) } />

                  <RadioButton
                    disabled={cnpjSearching || values.id}
                    name="identifyType"
                    label="Pessoa Física"
                    value="person"
                    onChange={(e) => OnChangeRadio(setFieldValue, e.target.value) } />
                </div>

                { values.id &&
                  <InputGroup
                    type="text"
                    name="id"
                    label="Unidade de negócio"
                    disabled={true} />
                }
                { values.id &&
                  <Inform disabled={true}>
                    <label>Data criação</label>
                    {formats.dateTimeZone(values.createdAt)}
                  </Inform>
                }

                <MaskInput
                  disabled={values.id}
                  mask={isJuridica(values) ? CNPJ_MASK : CPF_MASK}
                  name="identify"
                  label={isJuridica(values) ? "CNPJ" : "CPF"}
                  hasError={errors.identify && touched.identify}
                  onBlur={(e) => {
                      const value = onlyNumbers(e.target.value) || '';
                      setFieldTouched('identify', true);
                      setFieldValue('identify', value);
                      if (value && value.length === 14 && searchCnpj) {
                        searchCnpj(value);
                      }
                  }} />

                <InputGroup
                  type="text"
                  name="name"
                  label="Nome"
                  hasError={errors.name && touched.name}
                  disabled={cnpjSearching} />

                { isJuridica(values) &&
                  <InputGroup
                      type="tradeName"
                      name="tradeName"
                      label="Nome fantasia"
                      disabled={cnpjSearching} />
                }
                <MaskInput
                    name="phone"
                    mask={PHONE_MASK}
                    label="Telefone"
                    disabled={cnpjSearching} />

                {
                  !data.id && (
                    <>
                      <Divider label="Dados do Administrador" />
                      <InputGroup
                          type="text"
                          name="adminUsername"
                          label="Nome do usuário"
                          hasError={data.id && errors.adminUsername && touched.adminUsername}
                          disabled={cnpjSearching} />
                      <InputGroup
                          type="text"
                          name="adminEmail"
                          label="E-mail do usuário"
                          hasError={data.id && errors.adminEmail && touched.adminEmail}
                          disabled={cnpjSearching} />
                    </>
                  )
                }

                <Divider label="Endereço" />
                <InputGroup
                    type="text"
                    name="address.address"
                    label="Avenida, rua, ..."
                    disabled={cnpjSearching} />
                <InputGroup
                    type="text"
                    name="address.number"
                    disabled={cnpjSearching}
                    label="Número" />
                <InputGroup
                    type="text"
                    name="address.complements"
                    disabled={cnpjSearching}
                    label="Complemento" />
                <InputGroup
                    type="text"
                    name="address.block"
                    disabled={cnpjSearching}
                    label="Bairro" />
                <MaskInput
                    mask={CEP_MASK}
                    name="address.zip_code"
                    disabled={cnpjSearching}
                    label="CEP" />
                <InputGroup
                    type="text"
                    name="address.city"
                    disabled={cnpjSearching}
                    label="Cidade" />
                <Select
                    name="address.state"
                    label="UF"
                    options={
                        {
                            defaults: {value: "", label: "Selecione uma UF"},
                            values: Object.keys(UFS).map(key => ({"value": key, "label": UFS[key]}))
                        }
                    }
                    disabled={cnpjSearching}
                />

                <Divider label="Observações" />
                <Field
                  className="textarea"
                  name="notes"
                  component="textarea"
                  rows="4"
                  disabled={cnpjSearching} />

              {status && status.msg && <div>{status.msg}</div>}
            </StyledForm>
          </Modal>
        )
      }
    </Formik>
  );
}

CustomerForm.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func,
  handleCancelling: PropTypes.func
};

CustomerForm.defaultProps = {
  isOpen: false,
  handleOnSubmit: () => {},
  handleCancelling: () => {}
};

export default CustomerForm;
