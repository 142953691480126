import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Spinner from "~/components/spinner";
import get from "lodash/get";

const Container = styled.iframe`
  display: ${props => props.visible ? "auto" : "none"};
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Loading = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const IFrame = ({src, ...rest}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const toogleLoading = () => {
      setLoading(!loading);
    }

    const elements = document.getElementsByTagName('iframe');
    const element =  get(elements, '[0]');

    if (element) {
      element.addEventListener('load', toogleLoading);
    }

    return () => {
      if (element) {
        element.removeEventListener('load', toogleLoading);
      }
    }
  });

  return (
    <>
      <Container src={src ? src : ""} title="Report" {...rest} visible={!loading} />
      <Loading visible={loading}>
        <Spinner visible={true} />
      </Loading>
    </>
  );
}

export default IFrame;
