import styled, { css } from "styled-components";
import { MdClose } from "react-icons/md";

const controlsCss = css`
  position: absolute;
  cursor: pointer;
  z-index: 1;
  padding: 0.1em;
  transition: 0.3s;
  width: 1.6em;
  :hover {
    transform: scale(1.07);
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  opacity: ${props => (props.isOpen ? "1" : "0")};
  transition: 0.1s;
  z-index: 2;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  height: ${props => (props.children ? "40px" : "0px")};
  visibility: ${props => (props.children ? "visible" : "hidden")};
`;

const Container = styled.div`
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  background: ${props => props.background || "#fff"};
  width: ${props => (props.width ? props.width : "95%")};
  height: ${props => (props.height ? props.height : "95%")};
  position: relative;
  min-height: 150px;
  min-width: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 0.2em;
  transition: 0.3s;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Close = styled(MdClose)`
  ${controlsCss};
  border-radius: 0.4em;
  top: 0.7em;
  right: 0.5em;
  width: 20px;
  height: 20px;
`;

export { Background, Container, Title, Content, Close };
