import React from "react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import history from "~/services/history";
import GlobalStyle from "~/styles/global";
import "~/config/reactotron";
import { store, persistor } from "~/store";
import Routes from "~/routes";
import { createTheme } from 'react-data-table-component';
import { white, gray, link } from '~/components/mixins/color'

createTheme('segvis', {
  selected: {
    text: white.hex(),
    default: gray.string(),
  },
  highlightOnHover: {
    text: link.hex(),
    default: gray.string(),
  },
  divider: {
    default: '#fafafa',
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={2000} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
