import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import _ from "lodash";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
    searchCnpjRequest: ['cnpj'],
    searchCnpjSuccess: ['data'],
    searchCnpjFailed: [],
    createOrUpdateCustomer: ['data', 'actForm'],
    listCustomersRequest: ['page', 'term'],
    listCustomersFinish: ['data'],
    resetStateCustomer: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
    loading: false,
    hasMore: true,
    count: 0,
    page: 0,
    data: [],
    term: '',
    cnpjData: {},
    cnpjSearching: false
};

const listCustomersRequest = (state = INITIAL_STATE, action) => produce(state, draft => {
  draft.term = action.term;
  draft.loading = true;
  draft.count = 0;

});

const listCustomersFinish = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.loading = false;
    draft.data = _.get(action, "data.list", []);
    draft.count = _.get(action, "data.count", 0);
});

const resetStateCustomer = (state = INITIAL_STATE) => produce(state, draft => {
    draft.loading = false;
    draft.count = 0;
    draft.page = 0;
    draft.data = [];
    draft.term = null;
    draft.cnpjData = {};
    draft.cnpjSearching = false;
});

const searchCnpjRequest = (state = INITIAL_STATE) => produce(state, draft => {
    draft.cnpjSearching = true;
});

const searchCnpjSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.cnpjData = _.get(action, "data", {});
    draft.cnpjSearching = false;
});

const searchCnpjFailed = (state = INITIAL_STATE) => produce(state, draft => {
    draft.cnpjSearching = false;
});

/**
 * Reducers
 */
export default createReducer(INITIAL_STATE, {
    [Types.LIST_CUSTOMERS_REQUEST]: listCustomersRequest,
    [Types.LIST_CUSTOMERS_FINISH]: listCustomersFinish,
    [Types.SEARCH_CNPJ_REQUEST]: searchCnpjRequest,
    [Types.SEARCH_CNPJ_SUCCESS]: searchCnpjSuccess,
    [Types.SEARCH_CNPJ_FAILED]: searchCnpjFailed,
    [Types.RESET_STATE_CUSTOMER]: resetStateCustomer,
});
