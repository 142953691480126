import styled from "styled-components";

export const Container = styled.div`
  flex: 1 0 280px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0.7px 1px 1px 0 rgba(134, 134, 134, 0.45);
  padding: 3px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 15px);

  @media (max-width: 425px) {
    height: calc(100% - 135px);
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    padding: 0 10px;

    span {
      color: #444;
    }

    h2 {
      font-weight: 500;
      font-size: 16px;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        color: #666;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .cardList {
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;
