import React, { useState, useEffect, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import size from "lodash/size";
import toUpper from "lodash/toUpper";
import Header from "~/components/header";
import Cards from "~/components/cards";
import Spinner from "~/components/spinner";
import EmptyState from "~/components/empyt-state";
import FloatingActionButton from "~/components/floatbutton";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { addDays, subDays } from "date-fns";
import useWindowSize from "~/hooks/use-window-size";
import useModal from "~/hooks/use-modal";
import usePolling from "~/hooks/use-polling";
import Confirm from "~/components/confirm";
import { serviceTypes } from "~/constants";
import { Creators as InspectionActions } from "~/store/modules/inspections";
import { Container, Content, ActionsBar } from "./styles";
import AddForm from "../add-form";
import UpdateForm from "../update-form";
import "./kanban.css";
import { MdAdd, MdChevronLeft, MdChevronRight } from "react-icons/md";
import { ContextMenu, MenuItem } from "react-contextmenu";

registerLocale("pt-BR", ptBR);

const MENU_ID_CARD = "mnuContextCardKanban";

function Kanban() {
  const windowSize = useWindowSize();
  const now = new Date();
  const { isOpen, openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchDate, setSearchDate] = useState(now);
  const isLoggedCustomer = useSelector(state =>
    get(state, "profile.profile.is_customer")
  );
  const isLoggedAdministrator = useSelector(state =>
    get(state, "profile.profile.is_administrator")
  );
  const customer = useSelector(state => get(state, "profile.profile.account"));
  const restartPolling = useSelector(state =>
    get(state, "inspections.restartPolling")
  );

  const serviceModel = useSelector(state =>
    get(state, "inspections.serviceModel", {})
  );
  const showServiceUpdateValue = useSelector(state =>
    get(state, "inspections.showServiceUpdateValue", false)
  );

  function handleCancelServiceUpdateValue() {
    dispatch(InspectionActions.closeServiceUpdateValue());
  }

  async function handleSubmitServiceUpdateValue(data, actions) {
    dispatch(InspectionActions.serviceUpdateValue(data, actions));
  }

  const handleOnData = useCallback(response => {
    setData(get(response, "data"));
  }, []);

  const polling = usePolling({
    serviceName: "services/kanban",
    params: { date: searchDate },
    interval: 30000,
    onData: handleOnData,
    onError: console.log
  });

  useEffect(() => {
    if (searchDate && polling) {
      polling.start();
    }
    return () => polling.stop();
    // eslint-disable-next-line
  }, [searchDate]);

  useEffect(() => {
    if (restartPolling) {
      polling.start();
      dispatch(InspectionActions.resetRestartPolling());
    }
    // eslint-disable-next-line
  }, [restartPolling]);

  function changeSearchDate(newDate) {
    if (polling) {
      setSearchDate(previous => {
        polling.stop();
        return newDate;
      });
    }
  }

  function handleActions(evt) {
    evt.preventDefault();
    openModal();
  }

  function ButtonAdd() {
    return (
      <FloatingActionButton
        background="#578cf2"
        onClick={e => handleActions(e)}
      >
        <MdAdd />
      </FloatingActionButton>
    );
  }

  function searchCustomer(term, callback) {
    const mapping = data => {
      callback(
        data.map(customer => ({ label: customer.name, value: customer }))
      );
    };
    dispatch(InspectionActions.listCustomersInspectionRequest(term, mapping));
  }

  async function handleSubmit(data, actions) {
    const {
      board,
      customer: { name = "" }
    } = data;
    const text = `Deseja realmente efetuar a consulta para o cliente ${name} e veículo com placa ${toUpper(
      board
    )}?`;
    const result = await Confirm.show({
      title: "Confirmação",
      text
    });
    if (result) {
      actions.closeModal = closeModal;
      dispatch(InspectionActions.createInspectionRequest(data, actions));
    }
  }

  function handleReport(data) {
    const { id } = data;
    dispatch(InspectionActions.loadInspectionRequest(id));
  }

  async function dispacthRoolback(id, board) {
    const text = `Deseja realmente voltar o laudo, do veículo com placa ${toUpper(
      board
    )}, para vistoria?`;
    const result = await Confirm.show({
      title: "Confirmação",
      text
    });
    if (result) {
      dispatch(InspectionActions.inspectionRoolbackRequest(id));
    }
  }

  async function dispacthDataUpdate(id, board) {
    const text = `Deseja realmente solicitar atualização dos dados da consulta do veículo com placa ${toUpper(
      board
    )}?`;
    const result = await Confirm.show({
      title: "Confirmação",
      text
    });
    if (result) {
      dispatch(InspectionActions.inspectionDataUpdateRequest(id));
    }
  }

  async function dispacthRemoveTechnician(id, board) {
    const text = `Deseja realmente remover vistoriador do veículo com placa ${toUpper(
      board
    )}?`;
    const result = await Confirm.show({
      title: "Confirmação",
      text
    });
    if (result) {
      dispatch(InspectionActions.inspectionRemoveTechnicianRequest(id));
    }
  }

  function handleCardMenuContextClick(e, data, target) {
    const { action } = data;
    const id = target.getAttribute("id");
    const board = target.getAttribute("board");

    switch (action) {
      case "roolback":
        dispacthRoolback(id, board);
        break;
      case "update":
        dispacthDataUpdate(id, board);
        break;
      case "removeTechnician":
        dispacthRemoveTechnician(id, board);
        break;
      default:
        break;
    }
  }

  const KanbanHeader = memo(
    ({ loading, size, dateValue, onChangeDate, previousDate, nextDate }) => (
      <Header>
        <h3>Inspeções ({size})</h3>
        <ActionsBar>
          <Spinner visible={loading} />
          <MdChevronLeft size={25} onClick={previousDate} />
          <DatePicker
            selected={dateValue}
            onChange={onChangeDate}
            dateFormat={"dd/MM/yyyy"}
            locale="pt-BR"
          />
          <MdChevronRight size={25} onClick={nextDate} />
        </ActionsBar>
      </Header>
    )
  );

  const Boards = ({ list }) => {
    const array = [];
    for (let idx in serviceTypes) {
      const cards = list[idx] || [];
      const menuId = [
        "survey",
        "disapproved",
        "approved",
        "restrictions"
      ].includes(idx)
        ? MENU_ID_CARD
        : undefined;
      array.push(
        <Cards
          key={`cards.${idx}`}
          name={idx}
          label={serviceTypes[idx].label}
          data={cards}
          iconType={serviceTypes[idx].icon}
          handleClick={handleReport}
          handleMenuContextClick={handleCardMenuContextClick}
          menuContextId={menuId}
        />
      );
    }
    return array;
  };

  function count(data) {
    data = data || [];
    let count = 0;
    for (let idx in serviceTypes) {
      const cards = data[idx] || [];
      count += size(cards);
    }
    return count;
  }

  return (
    <Container>
      <KanbanHeader
        size={count(data)}
        loading={polling.running}
        dateValue={searchDate}
        onChangeDate={changeSearchDate}
        previousDate={() => changeSearchDate(subDays(searchDate, 1))}
        nextDate={() => changeSearchDate(addDays(searchDate, 1))}
      />

      <ButtonAdd />

      {size(data) === 0 && (
        <EmptyState
          text="nenhuma vistoria no momento"
          visible={size(data) === 0}
          size={windowSize.width <= 360 ? "130px" : "180px"}
        />
      )}

      <Content visible={size(data) > 0}>
        <Boards list={data} />
        {isOpen && (
          <AddForm
            customer={isLoggedCustomer ? customer : {}}
            isOpen={isOpen}
            closeModal={closeModal}
            handleCancelling={closeModal}
            handleSubmit={handleSubmit}
            onSearchCustomer={searchCustomer}
          />
        )}
        {showServiceUpdateValue && (
          <UpdateForm
            service={serviceModel}
            isOpen={showServiceUpdateValue}
            closeModal={handleCancelServiceUpdateValue}
            handleCancelling={handleCancelServiceUpdateValue}
            handleSubmit={handleSubmitServiceUpdateValue}
          />
        )}
        {isLoggedAdministrator && (
          <ContextMenu id={MENU_ID_CARD}>
            <MenuItem
              onClick={handleCardMenuContextClick}
              data={{ action: "roolback" }}
            >
              Enviar para Vistoria
            </MenuItem>
            <MenuItem
              onClick={handleCardMenuContextClick}
              data={{ action: "update" }}
            >
              Solicitar Atualização
            </MenuItem>
            <MenuItem
              onClick={handleCardMenuContextClick}
              data={{ action: "removeTechnician" }}
            >
              Retirar vistoriador
            </MenuItem>
          </ContextMenu>
        )}
      </Content>
    </Container>
  );
}

export default Kanban;
