import React from "react";
import PropTypes from "prop-types";
import { FieldArray, ErrorMessage } from "formik";
import styled from 'styled-components';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    hr {
        line-height: 1em;
        position: relative;
        outline: 0;
        border: 0;
        color: #000;
        text-align: center;
        height: 1.5em;
        opacity: .5;
        margin: 5px 0px;

        &:before {
            content: '';
            background: linear-gradient(to right, transparent, #000, transparent);
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
        }

        &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;
            color: #000;
            padding: 0 .5em;
            line-height: 1.5em;
            color: #000;
            background-color: #fcfcfa;
        }
    }

    input[type=checkbox] {
        margin-right: 5px;
        margin-bottom: 5px;
        width: 15px;
        height: 15px;
    }

    span {
        color: #555;
    }

    p {
        line-height: 30px;
    }
`;

const Box = styled.div`
    overflow-y: auto;
    height: ${props => props.height};
    padding: 0px 10px 10px;
    margin-bottom: 10px;
    border-left: 1px solid rgba(0,0,0,0.05);
    border-right: 1px solid rgba(0,0,0,0.05);
    border-bottom: 1px solid rgba(0,0,0,0.05);

`;

const GroupCheckbox = (props) => {
    const { label, name, values, allowedValues, height = "100%" } = props;

    return (
        <Container>
            <hr data-content={label} />
            <Box height={height}>
                <FieldArray
                    id={name}
                    name={name}>
                    {
                        ({push, remove, form}) => (
                            <div>
                                {
                                    Object.keys(allowedValues).map(key =>
                                        <div key={key}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={name}
                                                    checked={values.includes(key)}
                                                    onChange={
                                                        async (event) => {
                                                            if (event.target.checked) {
                                                                await push(key);
                                                            } else {
                                                                const idx = values.indexOf(key);
                                                                await remove(idx);
                                                            }
                                                            form.validateForm();
                                                        }
                                                    }
                                                />
                                                <span>
                                                    {allowedValues[key]}
                                                </span>
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </FieldArray>
            </Box>
            <ErrorMessage name={props.name} className={"error"} component="div" />
        </Container>
    )
}

GroupCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    values: PropTypes.array.isRequired,
    allowedValues: PropTypes.object.isRequired,
}

GroupCheckbox.defaultProps = {
    hasError: false,
    values: [],
    allowedValues: {}
}

export default GroupCheckbox;
