import React from "react";
import ReactDOM from "react-dom";
import { Background, Container, Title, Content, Close, Footer } from "./styles";

class Confirm extends React.Component {
  state = {
    isOpen: false,
    confirmProps: {}
  };

  resolve = null;

  static create(props = {}) {
    const el = document.createElement("div");
    document.body.appendChild(el);
    return ReactDOM.render(<Confirm confirmProps={props} />, el);
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    this.resolve(false);
  };

  handleConfirm = () => {
    this.setState({ isOpen: false });
    this.resolve(true);
  };

  show = (props = {}) => {
    const confirmProps = { ...this.props.confirmProps, ...props };
    this.setState({ isOpen: true, confirmProps });
    return new Promise(res => {
      this.resolve = res;
    });
  };

  render() {
    const { isOpen, confirmProps } = this.state;
    const { title, text, width, height } = confirmProps;
    if (!isOpen) return null;
    return (
      <Background isOpen={isOpen}>
        <Container height={height} width={width}>
          <Close onClick={this.handleCancel} />
          <Title>{title}</Title>
          <Content>{text}</Content>
          <Footer>
            <button
              type="button"
              className="negative"
              onClick={this.handleCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="positive"
              onClick={this.handleConfirm}
            >
              Confirmar
            </button>
          </Footer>
        </Container>
      </Background>
    );
  }
}

export default Confirm;
