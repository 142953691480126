import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import MaskedInput from "react-text-mask";
import styled from "styled-components";

const Group = styled.div`
  display: grid;
  position: relative;
  margin: 0 0 10px;

  input {
    background: rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 4px;
    padding: 22px 0 8px 10px;
    color: #555;

    &:focus {
      background: rgba(249, 185, 48, 0.16);
      color: #444;
    }

    &:disabled {
      background: #d8d8da;
      color: #777;
    }

    &:focus + label,
    &:not([value=""]) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: #888;
    }

    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      opacity: 0.7;
      color: #444;
    }
  }
`;

const MaskInput = ({ name, label = "", hasError, className, ...rest }) => (
  <>
    <Field type="text" id={name} name={name}>
      {({ field }) => (
        <Group>
          <MaskedInput
            {...field}
            {...rest}
            className={!!hasError ? "error" : ""}
          />
          <label htmlFor={name}>{label}</label>
        </Group>
      )}
    </Field>
    <ErrorMessage name={name} className="error" component="div" />
  </>
);

MaskInput.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.array.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool
};

MaskInput.defaultProps = {
  hasError: false
};

export default MaskInput;

// EXEMPLO DE USO
// <MaskInput
//     mask={PLACA_MASK}
//     name="phone"
//     label="Nome de usuário"
//     hasError={errors.phone && touched.phone} />
