import React from 'react';
import board from "~/assets/board.png";
import boardMercosul from "~/assets/board-mercosul.png";
import styled from "styled-components";
import * as helper from '~/helper';

const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 110px;
    height: 42.9px;
  }

  .center {
    font-size: 14px;
    top: 18px;
    font-weight: bold;
    position: absolute;
    text-transform: uppercase;
  }
`;

const Board = ({ value }) => {
  const imgBoard = /^\d+$/.test(value.substr(3, 4)) ? board : boardMercosul;
  const formattedValue = helper.formats.board(value)
  return (
    <BoardContainer>
      <img src={imgBoard} alt={formattedValue} />
      <span className="center">{formattedValue}</span>
    </BoardContainer>
  );
};

export default Board;
