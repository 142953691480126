import React, { memo } from "react";
import {MenuList} from "@reach/menu-button";
import styled from "styled-components";
import {
  Menu,
  MenuButton,
  MenuItem,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import "./dropdown.css";
import _ from 'lodash';

const ProfileMenuList = styled(MenuList)`
  padding: 0;
  margin-bottom: 5px;
  border: 1px solid #f5f5f5;

  > [data-reach-menu-item][data-selected] {
    background: #f5f5f5;
    color: #222;
  }
`;

const areEquals = (prevProps, nextProps) => {
  const key = 'children.props.avatar';
  return _.get(prevProps, key) === _.get(nextProps, key);
};

const MenuProfile = ({children, onAction}) => {
  return (
    <Menu>
      <MenuButton>
        {children}
      </MenuButton>
      <ProfileMenuList>
        <MenuItem onSelect={() => onAction('avatar')}>Alterar Foto</MenuItem>
        <MenuItem onSelect={() => onAction('pwd')}>Alterar senha</MenuItem>
      </ProfileMenuList>
    </Menu>
  );
}

export default memo(MenuProfile, areEquals);
