
import { createActions, createReducer } from "reduxsauce";
import produce from "immer";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
    signInRequest: ['data', 'actForm'],
    signInSuccess: ['token', 'user'],
    signFailure: ['error'],
    signOut: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
    token: null,
    signed: false,
    loading: false,
};

const signInRequest = (state = INITIAL_STATE) => produce(state, draft => { draft.loading = true });

const signInSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.token = action.token;
    draft.signed = true;
    draft.loading = false;
});

const signFailure = (state = INITIAL_STATE) => produce(state, draft => { draft.loading = false });

const singOut = (state = INITIAL_STATE) => produce(state, draft => {
    draft.token = null;
    draft.signed = false;
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
    [Types.SIGN_IN_REQUEST]: signInRequest,
    [Types.SIGN_IN_SUCCESS]: signInSuccess,
    [Types.SIGN_FAILURE]: signFailure,
    [Types.SIGN_OUT]: singOut,
});
