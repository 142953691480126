import React from "react";
import PropTypes from "prop-types";
import Menu from "~/components/menu";
import { Wrapper, Container } from "./styles";

export default function Default({ children }) {
  return (
    <Wrapper>
      <Menu />
      <Container>{children}</Container>
    </Wrapper>
  );
}

Default.propTypes = {
  children: PropTypes.element.isRequired
};
