import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    padding: 10px;
    min-width: 300px;

    h1 {
        font-family: 'Comfortaa', cursive;
        font-size: 100px;
        text-align: center;
        color: #eee;
        font-weight: 100;
        margin: 0;
    }

    p {
        font-family: 'Comfortaa', cursive;
        color: #aaa;
        font-size: 18px;
    }
`;
