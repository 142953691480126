import React from "react";
import styled from "styled-components";
import _ from "lodash";
import {formats} from "~/helper";

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    height: 40px;
    font-size: 13px;
    bottom: 0;
    position:absolute;
    width: 100%;
    border-top: thin solid #efefef;
    color: #555;
`;

const Address = styled.div`
  margin-left: 20px;
`;

const PageNumber = styled.div`
  display: flex;
  font-weight: 600;
  color: #555;
  margin-right: 20px
`;

function pprint(str, data, field) {
    const value = _.get(data, field);
    if (_.isEmpty(value)) return "";
    let tmp = `${str}${_.trim(value)}`;
    return tmp;
}

function print(str, value) {
    if (_.isEmpty(value)) return "";
    let tmp = `${str}${_.trim(value)}`;
    return tmp;
}

const RenderFooter = ({model, page}) => {
    const agent = _.get(model, 'customer.parent') || {};
    const address = _.get(agent, "address") || {};
    const phone = _.get(agent, "phone") || '';

    return (
        <Footer>
          <Address>
            {pprint('', address, 'address')}{pprint(', ', address, 'number')}
            {pprint(' - ', address, 'complements')}{pprint(', ', address, 'block')}
            {pprint(', ', address, 'city')}{pprint(' - ', address, 'state')}
            {print(', ', formats.cep(_.get(address, 'zip_code')))}
            {print(', ', formats.phone(phone))}
          </Address>
          <PageNumber>Página: {page}</PageNumber>
        </Footer>
    )
};

export default RenderFooter;
