import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Modal from "~/components/modal";
import styled from "styled-components";
import * as Yup from "yup";
import { InputGroup } from "~/components/form";

const Container = styled(Form)`
    display: flex;
    flex-direction: column;

    span {
        color: #d61305;
        margin: 0 0 10px;
    }
`;

const schema = Yup.object().shape({
    oldPassword: Yup.string().required("Informe a sua senha atual"),
    password: Yup.string().required("Informe a sua nova senha"),
    confirmPassword: Yup.string().required("Confirme a sua nova senha")
});

function PasswordForm({
  title,
  data,
  isOpen,
  closeModal,
  handleOnSubmit,
  handleCancelling
}) {

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
    >
      {
        ({
            errors,
            isValid,
            touched,
            handleSubmit,
            isSubmitting,
            handleReset
        }) => (
          <Modal
            width="350px"
            height="350px"
            open={isOpen}
            hide={closeModal}
            title={title}
            positive={{
                text: isSubmitting ? "Aguarde..." : "Enviar",
                action: handleSubmit,
                disabled: !isValid || isSubmitting
            }}
            negative={{
                text: "Cancela",
                action: () => handleReset() && handleCancelling(),
                disabled: isSubmitting
            }}
          >
            <Container>
                <InputGroup
                  type="password"
                  name="oldPassword"
                  label="Senha atual"
                  hasError={errors.oldPassword && touched.oldPassword} />

                <InputGroup
                  type="password"
                  name="password"
                  label="Nova senha"
                  hasError={errors.password && touched.password} />

                <InputGroup
                  type="password"
                  name="confirmPassword"
                  label="Confirmação de  senha"
                  hasError={errors.confirmPassword && touched.confirmPassword} />
            </Container>
          </Modal>
        )
      }
    </Formik>
  );
}

PasswordForm.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func,
  handleCancelling: PropTypes.func
};

PasswordForm.defaultProps = {
  isOpen: false,
  handleOnSubmit: () => {},
  handleCancelling: () => {}
};

export default PasswordForm;
