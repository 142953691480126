import { combineReducers } from "redux";
import auth from "~/store/modules/auth";
import profile from "~/store/modules/profile";
import users from "~/store/modules/users";
import customers from "~/store/modules/customers";
import accounts from "~/store/modules/accounts";
import inspections from "~/store/modules/inspections";
import reports from "~/store/modules/reports";

export default combineReducers({
  auth,
  profile,
  users,
  accounts,
  customers,
  inspections,
  reports,
});
