import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Modal from "~/components/modal";
import styled from "styled-components";

import FileImageCrop from "~/components/form/file-image-crop";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

function AvatarForm({
  title,
  data,
  isOpen,
  closeModal,
  handleOnSubmit,
  handleCancelling
}) {

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      onSubmit={handleOnSubmit}
    >
      {
        ({
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            width="350px"
            height="350px"
            open={isOpen}
            hide={closeModal}
            title={title}
            positive={{
              text: isSubmitting ? "Aguarde..." : "Enviar",
              action: handleSubmit,
              disabled: isSubmitting
            }}
            negative={{
              text: "Cancela",
              action: () => handleReset() && handleCancelling(),
              disabled: isSubmitting
          }}
          >
            <Container>
              <FileImageCrop
                label="clique aqui para selecionar sua foto"
                name="avatar"
                aspect={1}
                height={"100%"}
              />
            </Container>
          </Modal>
        )
      }
    </Formik>
  );
}

AvatarForm.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func,
  handleCancelling: PropTypes.func
};

AvatarForm.defaultProps = {
  isOpen: false,
  handleOnSubmit: () => {},
  handleCancelling: () => {}
};

export default AvatarForm;
