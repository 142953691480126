import { useState, useEffect, useCallback } from "react";
import api from "~/services/api";
import axios from "axios";

const usePolling = props => {
  const { serviceName, interval, onData, onError, params = {} } = props;
  const [execute, setExecute] = useState(false);
  const [polling, setPolling] = useState();

  const stop = useCallback(() => {
    if (polling) {
      clearInterval(polling);
      setPolling(null);
    }
  }, [polling]);

  const start = useCallback(() => {
    stop();
    setTimeout(() => setExecute(true), 100);
    setPolling(
      setInterval(() => {
        setExecute(true);
      }, interval)
    );
  }, [interval, stop]);

  useEffect(() => {
    return stop;
  }, [stop]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    function run() {
      api
        .get(serviceName, { params, cancelToken: source.token })
        .then(onData)
        .catch(onError)
        .finally(() => setExecute(false));
    }

    if (execute) run();

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [execute]);

  return {
    start,
    stop,
    running: execute
  };
};

export default usePolling;
