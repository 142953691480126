import React, { useState, useEffect, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import size from "lodash/size";
import useModal from "~/hooks/use-modal";
import Header from "~/components/header";
import Spinner from "~/components/spinner";
import { Search } from "../../components/form";
import FloatingActionButton from "~/components/floatbutton";
import {
  Container,
  Content,
  ActionsBar,
} from "./styles";
import { MdAdd, MdEdit, MdContacts } from "react-icons/md";
import { formats } from "~/helper";
import CustomerForm from "./form";
import { Creators as CustomerActions } from "~/store/modules/customers";
import { CellContainer, Square } from "../inspection/list/styles";
import DataTable from "react-data-table-component";
import _ from 'lodash';

export default function Customer() {
  const dispatch = useDispatch();
  const { isOpen, openModal, closeModal } = useModal();
  const [customer, setCustomer] = useState({});
  const [title, setTitle] = useState();
  const state = useSelector(state => state.customers);

  const columns = useMemo(
    () => [
      {
        name: "# Cliente",
        selector: "name",
        cell: row => {
          return (
            <CellContainer>
              <Square inUpdate={!row.active} />
              <strong>{formats.cnpj_cpf(row.identify)} - {row.name}</strong>
            </CellContainer>
          );
        }
      },
      {
        name: "Telefone",
        selector: "phone",
        cell: row => (
          <strong className="remove-point-events-datatable">
            {_.get(row, "phone") || ""}
          </strong>
        )
      },
      {
        name: "Data",
        selector: "createdAt",
        hide: "md",
        cell: row =>
          formats.dateTimeZone(_.get(row, "createdAt"), "dd/MM/yyyy HH:mm:ss")
      }
    ],
    []
  );

  useEffect(() => {
    if (state.cnpjData) {
      setCustomer(state.cnpjData);
    }
  }, [state.cnpjData]);

  useEffect(() => {
    const reset = () => dispatch(CustomerActions.resetStateCustomer());
    dispatch(CustomerActions.listCustomersRequest());
    return () => {
      reset();
    };
  }, [dispatch]);

  function handleActions(evt, data, act) {
    evt.preventDefault();
    setCustomer(data);
    configTitle(act);
    openModal();
  }

  const handlePageChange = page => {
    dispatch(CustomerActions.listCustomersRequest(page));
  };

  function searching(text) {
    dispatch(CustomerActions.listCustomersRequest(0, text));
  }

  function handleOnSubmit(data, actions) {
    actions.closeModal = () => {
      setCustomer({});
      closeModal();
    };
    dispatch(CustomerActions.createOrUpdateCustomer(data, actions));
  }

  const CustomerHeader = memo(({ loading, count }) => {
    return (
      <Header>
        <h3>Gestão de clientes ({count})</h3>
        <ActionsBar>
          <Spinner visible={loading} />
          <Search onSearch={searching} />
        </ActionsBar>
      </Header>
    );
  });

  function configTitle(act) {
    const text = act === "add" ? "Adicionar" : "Editar";
    setTitle(
      <>
        {act === "add" ? <MdContacts /> : <MdEdit />}
        &nbsp;&nbsp;<span>{text} cliente</span>
      </>
    );
  }

  function ButtonAdd(props) {
    return (
      <FloatingActionButton
        background="#578cf2"
        onClick={e =>
          handleActions(
            e,
            {
              active: true,
              identifyType: "company",
              identify: null,
              phone: null,
              address: { zip_code: null }
            },
            "add"
          )
        }
      >
        <MdAdd />
      </FloatingActionButton>
    );
  }

  return (
    <Container>
      <CustomerHeader loading={state.loading} count={state.count} />
      <ButtonAdd />
      {isOpen && (
        <CustomerForm
          title={title}
          data={customer}
          isOpen={isOpen}
          closeModal={closeModal}
          handleOnSubmit={handleOnSubmit}
          searchCnpj={value => {
            dispatch(CustomerActions.searchCnpjRequest(value));
          }}
          cnpjSearching={state.cnpjSearching}
        />
      )}
      <Content visible={size(state.data) > 0}>
        <DataTable
          theme="segvis"
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          dense
          persistTableHead
          showRowHover
          overflowY
          columns={columns}
          data={_.get(state, "data", [])}
          noDataComponent={
            <p>
              <br />
              <strong>Nenhum registro encontrado!</strong>
              <br />
              <br />
            </p>
          }
          onRowClicked={(row, e) => handleActions(e, row, "edit")}
          page={_.get(state, "page") || 0}
          paginationPerPage={15}
          pagination
          paginationServer
          paginationTotalRows={_.get(state, "count") || 0}
          onChangePage={handlePageChange}
          paginationComponentOptions={{
            noRowsPerPage: true,
            rangeSeparatorText: "de"
          }}
        />
      </Content>
    </Container>
  );
}
