import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "~/components/modal";
import { StyledForm } from './styles';
import { Checkbox, GroupCheckbox, InputGroup } from "../../components/form";
import useWindowSize from "~/hooks/use-window-size";

function UserForm({
  title,
  data,
  modulesView,
  isOpen,
  closeModal,
  handleOnSubmit,
  handleCancelling= () => {}
}) {

  const windowSize = useWindowSize();

  const schema = Yup.object().shape({
    username: Yup.string().required("É necessário informar um nome de usuário"),
    name: Yup.string().min(4, "Verifique se o seu nome está correto").required("Informar o seu nome"),
    email: Yup.string().email("Informe um e-mail válido").required("Informe um e-mail válido"),
    permissions: Yup.array().required('Verifique as permissões de acesso'),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={schema}
      initialValues={data}
      onSubmit={handleOnSubmit}
      initialTouched={{
        'permissions': true
      }}
    >
      {
        ({
          errors,
          isValid,
          status,
          touched,
          handleSubmit,
          handleReset,
          isSubmitting,
          values,

        }) => (
          <Modal
            width="400px"
            height={windowSize.height < 470 ? "90%" : "470px"}
            allowFullscreen={true}
            open={isOpen}
            hide={closeModal}
            title={title}
            positive={{
              text: isSubmitting ? "Aguarde..." : "Confirma",
              action: handleSubmit,
              disabled: !isValid || isSubmitting
            }}
            negative={{
              text: "Cancela",
              action: () => handleReset() && handleCancelling(),
              disabled: isSubmitting
            }}
          >
            <StyledForm>
              <Checkbox
                name="active"
                label="Ativo"
              />

              <InputGroup
                disabled={values.id}
                type="text"
                name="username"
                label="Nome de usuário"
                hasError={errors.username && touched.username} />

              <InputGroup
                type="text"
                name="name"
                label="Nome"
                hasError={errors.name && touched.name} />

              <InputGroup
                type="email"
                name="email"
                label="E-mail"
                hasError={errors.email && touched.email} />

              <GroupCheckbox
                height="90px"
                label="Permissões"
                name="permissions"
                values={values.permissions}
                allowedValues={modulesView} />

              {status && status.msg && <div>{status.msg}</div>}
            </StyledForm>
          </Modal>
        )
      }
    </Formik>
  );
}

UserForm.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func,
  handleCancelling: PropTypes.func
};

UserForm.defaultProps = {
  isOpen: false,
  handleOnSubmit: () => {},
  handleCancelling: () => {}
};

export default UserForm;
