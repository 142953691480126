import styled from "styled-components";
import { primary, gray, white, darkGray } from "~/components/mixins/color";

export const Container = styled.div`
  height: 100%;
  min-width: 77px;
  background: ${white.hex()};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 77px;

  img {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
    padding-bottom: 15px;
    width: 40px;
  }

  nav {
    display: flex;
    justify-content: center;
    height: calc(100vh - 135px);
    max-height: calc(100vh - 135px);
    overflow-x: hidden;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
    > ul {
      height: 100%;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      cursor: pointer;
      color: ${darkGray.hex()};
      width: 77px;
    }
    @media (max-width: 1024px) {
      nav {
        height: calc(100vh - 165px);
        max-height: calc(100vh - 165px);
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${darkGray.hex()};
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      height: 38px;

      &.active {
        border-right: 2px solid ${primary.hex()};
        background: ${primary.fade(0.9).string()};
        li {
          color: ${primary.hex()};

          &:hover {
            background: ${primary.hex()};
            color: ${white.hex()};
          }
        }
      }

      &:hover {
        background: ${primary.hex()};
        border-right: none;
        border-top: 1px solid ${primary.hex()};
        border-bottom: 1px solid ${primary.hex()};
        color: ${white.hex()} !important;

        li {
          color: ${white.hex()};
        }
      }
    }

    .app-side-menu {
      display: flex !important;
      align-items: center !important;
      width: 230px !important;
      height: 38px !important;
      font-weight: bold !important;
      
      border: 1px solid ${primary.hex()} !important;
      border-left: none !important;
      border-radius: 0px 3px 3px 0px !important;

      background: ${primary.hex()};
      opacity: 1;
      color: ${white.hex()} !important;
      
      padding: 0px !important;
      padding-left: 10px !important;
      
      margin-top: 1px !important;
      margin-left: -1px !important;
    }

    .app-side-menu::after {
      display: none !important;
      opacity: 0 !important;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin: 3px;
  padding: 5px;

  @media (min-width: 769px) {
    display: none;
  }

  img {
    cursor: pointer;
    height: 22px;
    width: 22px;
    border-radius: 3px;
  }
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;

  .menu-bottom li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${darkGray.hex()};
    cursor: pointer;
    width: 70px;

    &:hover {
      border-right: none;
      background: ${gray.fade(0.9).string()};
      border-top: 1px solid ${gray.hex()};
      border-bottom: 1px solid ${gray.hex()};
      padding: 5px;
    }
  }
`;
