import axios from "axios";
import { BASE_URL } from "~/constants";
import { Creators as AuthActions } from "~/store/modules/auth";
import { store } from "~/store";
import _ from 'lodash';

const api = axios.create({
  baseURL: BASE_URL
});

api.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (_.get(error, 'response.status') === 403) {
      store.dispatch(AuthActions.singOut());
      return Promise.reject({
        error: { message: "Acesse novamente o sistema, seu login expirou!" }
      });
    } else {
      return Promise.reject(error);
    }
  }
);

axios.defaults.headers.common["Content-Type"] = "application/json";

api.download = function(response, filename) {
  const url = window.URL.createObjectURL(new Blob([response.data])),
    link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default api;
