import React from "react";
import ReactDOM from "react-dom";
import {
    Background,
    ModalContainer,
    Title,
    ModalContent,
    Close,
    FullscreenToggle,
    PrinterButton,
    Container,
    ExtraActionButton
} from "./styles";
import ReactToPrint from "react-to-print";
class Print extends React.Component {
  state = {
    isOpen: false,
    isFullscreen: false,
    printerProps: {} // title, component child
  };

  resolve = null;

  static create(props = {}) {
    const el = document.createElement("div");
    document.body.appendChild(el);
    return ReactDOM.render(<Print printerProps={props} />, el);
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    this.resolve(false);
  };

  handleConfirm = () => {
    this.setState({ isOpen: false });
    this.resolve(true);
  };

  handleIsFullscreen = () => {
    this.setState(prevState => ({...prevState, isFullscreen: !prevState.isFullscreen}));
  }

  show = (props, size) => {
    const printerProps = { ...this.props.printerProps, ...props };
    this.setState({ isOpen: true, size, printerProps });
    return new Promise(res => {
      this.resolve = res;
    });
  }

  constructor(props) {
    super(props);
    this.modalContentRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.modalContentRef && this.modalContentRef.current) {
      this.modalContentRef.current.scrollTop = 1;
    }
  }

  render() {
    const { isOpen, isFullscreen, printerProps } = this.state;
    const { title, width, height, component, extraActions = [] } = printerProps;
    const lastRight = 3.7

    return (
      <Background isOpen={isOpen}>
        <ModalContainer height={height} width={width} fullscreen={isFullscreen}>
          <Close
            onClick={this.handleCancel} />
          <FullscreenToggle
            fullscreen={isFullscreen.toString()}
            onClick={this.handleIsFullscreen}
          />
          <ReactToPrint
            trigger={() => <PrinterButton />}
            content={() => this.pageRef}
          />
          {
            extraActions.map((action, index) => {
              const { icon: Icon, handleClick } = action;
              return (
                <ExtraActionButton
                  key={`extra-action.${index}`}
                  right={lastRight + 1.7}
                  onClick={handleClick}>
                    <Icon />
                </ExtraActionButton>
              );
            })
          }
          <Title>{title}</Title>
          <ModalContent ref={this.modalContentRef}>
            <Container ref={el => this.pageRef = el}>
              {component}
            </Container>
          </ModalContent>
        </ModalContainer>
      </Background>
    );
  }

}

export default Print;
