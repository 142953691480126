import React, { useEffect, useCallback, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Header from "~/components/header";
import Spinner from "~/components/spinner";
import {
  Container,
  Content,
  ActionsBar,
  CellContainer,
  Square
} from "./styles";
import DataTable from "react-data-table-component";
import { Creators as InspectionActions } from "~/store/modules/inspections";
import { serviceTypes } from "~/constants";
import { formats } from "~/helper";
import { Search } from "~/components/form";
import UpdateForm from "../update-form";
import Report from "~/components/reports";

export default function List() {
  const dispatch = useDispatch();
  const state = useSelector(state => state.inspections);
  const serviceModel = _.get(state, "serviceModel", {});
  const showServiceUpdateValue = _.get(state, "showServiceUpdateValue", false);

  const updateState = useCallback(
    state => {
      const { position, id, link } = state;

      if (position === "survey") {
        Report.show({ link });
      } else if (
        ["disapproved", "approved", "restrictions"].indexOf(position) !== -1
      ) {
        dispatch(InspectionActions.loadInspectionRequest(id));
      }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        name: "# Placa",
        selector: "board",
        cell: row => {
          const board = _.upperCase(row.board);
          return (
            <CellContainer>
              <Square inUpdate={row.inUpdate} />
              <strong>{formats.board(board)}</strong>
            </CellContainer>
          );
        }
      },
      {
        name: "Status",
        selector: "position",
        hide: "md",
        cell: row => (
          <strong className="remove-point-events-datatable">
            {serviceTypes[row.position].label}
          </strong>
        )
      },
      {
        name: "Data",
        selector: "createdAt",
        hide: "md",
        cell: row =>
          formats.dateTimeZone(_.get(row, "createdAt"), "dd/MM/yyyy HH:mm:ss")
      },
      {
        name: "Cliente",
        selector: row => _.capitalize(_.get(row, "customer.name"))
      },
      {
        name: "Técnico",
        hide: "md",
        selector: row => _.capitalize(_.get(row, "technician.name"))
      },
      {
        name: "Quem solicitou",
        hide: "md",
        selector: row => _.capitalize(_.get(row, "requester.name"))
      },
      {
        name: "Estrutura",
        selector: "hasStructure",
        hide: "md",
        center: true,
        cell: row => (row.hasStructure ? "Sim" : "Não")
      },
      {
        name: "Pintura",
        selector: "hasPaint",
        hide: "md",
        center: true,
        cell: row => (row.hasPaint ? "Sim" : "Não")
      },
      {
        name: "Consulta",
        selector: "hasSearch",
        hide: "md",
        center: true,
        cell: row => (row.hasSearch ? "Sim" : "Não")
      }
    ],
    []
  );

  useEffect(() => {
    dispatch(InspectionActions.findingServiceRequest());
  }, [dispatch]);

  const handlePageChange = page => {
    dispatch(InspectionActions.findingServiceRequest(page));
  };

  const searching = term => {
    dispatch(InspectionActions.findingServiceRequest(0, term));
  };

  function handleCancelServiceUpdateValue() {
    dispatch(InspectionActions.closeServiceUpdateValue());
  }

  function handleSubmitServiceUpdateValue(data, actions) {
    dispatch(InspectionActions.serviceUpdateValue(data, actions));
  }

  const InspectionsHeader = memo(({ loading, size }) => (
    <Header>
      <h3>Pesquisa nas vistorias ({size})</h3>
      <ActionsBar>
        <Spinner visible={loading} />
        <Search onSearch={searching} />
      </ActionsBar>
    </Header>
  ));

  return (
    <Container>
      <InspectionsHeader size={state.count || 0} loading={state.loading} />
      {showServiceUpdateValue && (
        <UpdateForm
          service={serviceModel}
          isOpen={showServiceUpdateValue}
          closeModal={handleCancelServiceUpdateValue}
          handleCancelling={handleCancelServiceUpdateValue}
          handleSubmit={handleSubmitServiceUpdateValue}
        />
      )}
      <Content visible={true}>
        <DataTable
          theme="segvis"
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          dense
          persistTableHead
          showRowHover
          overflowY
          columns={columns}
          data={_.get(state, "data", [])}
          noDataComponent={
            <p>
              <br />
              <strong>Nenhum registro encontrado!</strong>
              <br />
              <br />
            </p>
          }
          onRowClicked={updateState}
          page={state.page}
          paginationPerPage={15}
          pagination
          paginationServer
          paginationTotalRows={state.count || 0}
          onChangePage={handlePageChange}
          paginationComponentOptions={{
            noRowsPerPage: true,
            rangeSeparatorText: "de"
          }}
        />
      </Content>
    </Container>
  );
}
