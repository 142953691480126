import _ from "lodash";
import React from "react";
import { A4Page } from "~/components/print";
import { formats } from "~/helper";
import RenderFooter from "~/pages/inspection/reports/blend/footer";
import RenderHeader from "~/pages/inspection/reports/blend/header";
import {
  Container,
  Field,
  Grid,
  Line,
  normalizeData,
  normalizeRestrictions
} from "~/pages/inspection/reports/blend/helper";
import RenderSubSection from "~/pages/inspection/reports/blend/sub-section";
import RenderTable from "~/pages/inspection/reports/blend/table";
import * as imagesLogos from "~/assets/logos";

const getImageLogo = logo => {
  const keyLogo = `_${logo}`;

  if (!logo) {
    return null;
  }

  return imagesLogos[keyLogo];
};

function Informations({ model = {}, getPage = _.noop }) {
  const accountId = _.get(model, 'customer.parent.id');
  const imageLogo = getImageLogo(accountId);
  const companyConferi = _.get(model, "company_conferi", {});
  const brand =
    _.get(companyConferi, "agregados.marca") ||
    _.get(companyConferi, "bin.marca") ||
    _.get(companyConferi, "respostaEstadual.marca") ||
    "";

  return (
    <A4Page>
      <Container>
        <RenderHeader
          accountId={accountId}
          position={model.position}
          board={model.board}
          brand={brand}
        />

        <RenderTable
          title="LAUDO DE VISTORIA VEICULAR"
          data={[
            {
              label: "Data da vistoria",
              value: formats.dateTimeZone(
                _.get(model, "surveyAt"),
                "dd/MM/yyyy HH:mm"
              )
            },
            {
              label: "Número",
              value: formats.fill(_.get(model, "id"), 10, "0")
            },
            {
              label: "Data da solicitação",
              value: formats.dateTimeZone(
                _.get(model, "createdAt"),
                "dd/MM/yyyy HH:mm"
              )
            },
            {
              label: "Vistoriador",
              value: _.upperCase(_.get(model, "technician.name"))
            }
          ]}
        />

        <RenderTable
          title="DADOS DO CLIENTE"
          data={[
            {
              label: "Nº Documento",
              value: formats.cnpj_cpf(_.get(model, "customer.identify"))
            },
            { label: "Nome", value: _.upperCase(_.get(model, "customer.name")) }
          ]}
        />

        <RenderTable
          title="INFORMAÇÕES DO PROPRIETÁRIO"
          data={[
            {
              label: "Nome",
              value: _.upperCase(
                _.get(companyConferi, "respostaEstadual.proprietario")
              )
            },
            {
              label: "Tipo/Nº Documento",
              value: `${_.get(
                companyConferi,
                "respostaEstadual.tipoDocProprietario",
                ""
              )}/${formats.cnpj_cpf(
                _.get(companyConferi, "respostaEstadual.docProprietario", "")
              )}`
            }
          ]}
        />

        <RenderTable
          title="INFORMAÇÕES DO VEÍCULO - BASE ESTADUAL"
          data={[
            { label: "Placa", value: formats.board(_.get(model, "board", "")) },
            {
              label: "Situação",
              value: _.get(companyConferi, "respostaEstadual.situacao", "")
            },
            {
              label: "Marca/Modelo",
              value: `${_.get(
                companyConferi,
                "respostaEstadual.modelo",
                ""
              )}/${_.get(companyConferi, "respostaEstadual.marca", "")}`
            },
            {
              label: "Tipo de veículo",
              value: _.get(companyConferi, "respostaEstadual.tipoVeiculo", "")
            },
            {
              label: "Ano fabricação/modelo",
              value: `${_.get(
                companyConferi,
                "respostaEstadual.anoFabricacao",
                ""
              )}/${_.get(companyConferi, "respostaEstadual.anoModelo", "")}`
            },
            {
              label: "Procedência",
              value: _.get(companyConferi, "respostaEstadual.procedencia", "")
            },
            {
              label: "Município/UF",
              value: `${_.get(
                companyConferi,
                "respostaEstadual.municipio",
                ""
              )}/${_.get(companyConferi, "respostaEstadual.uf", "")}`
            },
            {
              label: "Tipo Chassi",
              value: _.get(companyConferi, "respostaEstadual.tipoChassi", "")
            },
            {
              label: "Tipo Carroceria",
              value: _.get(
                companyConferi,
                "respostaEstadual.tipoCarroceria",
                ""
              )
            },
            {
              label: "Chassi",
              value: _.get(companyConferi, "respostaEstadual.chassi", "")
            },
            {
              label: "Motor",
              value: _.get(companyConferi, "respostaEstadual.motor", "")
            },
            {
              label: "Renavam",
              value: _.get(companyConferi, "respostaEstadual.renavam", "")
            },
            {
              label: "Combustível",
              value: _.get(companyConferi, "respostaEstadual.combustivel", "")
            },
            {
              label: "Cor",
              value: _.get(companyConferi, "respostaEstadual.cor", "")
            },
            {
              label: "Capacidade de passageiros",
              value: _.get(
                companyConferi,
                "respostaEstadual.qtdPassageiros",
                ""
              )
            },
            {
              label: "Espécie",
              value: _.get(companyConferi, "respostaEstadual.especie", "")
            },
            {
              label: "Cilindradas",
              value: _.get(companyConferi, "respostaEstadual.cilindradas", "")
            },
            {
              label: "Peso bruto (PBT)",
              value: _.get(companyConferi, "respostaEstadual.pbt", "")
            },
            {
              label: "Peso bruto (CMT)",
              value: _.get(companyConferi, "respostaEstadual.cmt", "")
            },
            {
              label: "Potência",
              value: _.get(companyConferi, "respostaEstadual.potencia", "")
            },
            {
              label: "Eixo",
              value: _.get(companyConferi, "respostaEstadual.numeroEixos", "")
            },
            {
              label: "Capacidade de carga",
              value: _.get(
                companyConferi,
                "respostaEstadual.capacidadeCarga",
                ""
              )
            },
            {
              label: "Data emissão CRV",
              value: _.get(
                companyConferi,
                "respostaEstadual.dataEmissaoCRV",
                ""
              )
            },
            {
              label: "Última atualização",
              value: _.get(
                companyConferi,
                "respostaEstadual.ultimaAtualizacao",
                ""
              )
            }
          ]}
        />

        {_.get(model, "searchType") === "full" && (
          <RenderTable
            title="INFORMAÇÕES DO VEÍCULO - BASE FEDERAL"
            data={[
              { label: "Placa", value: _.toUpper(_.get(model, "board", "")) },
              {
                label: "Situação",
                value: _.get(companyConferi, "bin.situacao", "")
              },
              {
                label: "Marca/Modelo",
                value: `${_.get(companyConferi, "bin.modelo", "")}/${_.get(
                  companyConferi,
                  "bin.marca",
                  ""
                )}`
              },
              {
                label: "Tipo de veículo",
                value: _.get(companyConferi, "bin.tipoVeiculo", "")
              },
              {
                label: "Ano fabricação/modelo",
                value: `${_.get(
                  companyConferi,
                  "bin.anoFabricacao",
                  ""
                )}/${_.get(companyConferi, "bin.anoModelo", "")}`
              },
              {
                label: "Procedência",
                value: _.get(companyConferi, "bin.procedencia", "")
              },
              {
                label: "Município/UF",
                value: `${_.get(companyConferi, "bin.municipio", "")}/${_.get(
                  companyConferi,
                  "bin.Uf",
                  ""
                )}`
              },
              {
                label: "Tipo Chassi",
                value: _.get(companyConferi, "bin.tipoChassi", "")
              },
              {
                label: "Tipo Carroceria",
                value: _.get(companyConferi, "bin.tipoCarroceria", "")
              },
              {
                label: "Chassi",
                value: _.get(companyConferi, "bin.chassi", "")
              },
              { label: "Motor", value: _.get(companyConferi, "bin.motor", "") },
              {
                label: "Renavam",
                value: _.get(companyConferi, "bin.renavam", "")
              },
              {
                label: "Combustível",
                value: _.get(companyConferi, "bin.combustivel", "")
              },
              { label: "Cor", value: _.get(companyConferi, "bin.cor", "") },
              {
                label: "Capacidade de passageiros",
                value: _.get(companyConferi, "bin.lotacao", "")
              },
              {
                label: "Espécie",
                value: _.get(companyConferi, "bin.especie", "")
              },
              {
                label: "Cilindradas",
                value: _.get(companyConferi, "bin.cilindradas", "")
              },
              {
                label: "Peso bruto (PBT)",
                value: _.get(companyConferi, "bin.pbt", "")
              },
              {
                label: "Peso bruto (CMT)",
                value: _.get(companyConferi, "bin.cmt", "")
              },
              {
                label: "Potência",
                value: _.get(companyConferi, "bin.potencia", "")
              },
              {
                label: "Eixo",
                value: _.get(companyConferi, "bin.numeroEixos", "")
              },
              {
                label: "Capacidade de carga",
                value: _.get(companyConferi, "bin.capacidadeCarga", "")
              },
              {
                label: "Última atualização",
                value: _.get(companyConferi, "bin.ultimaAtualizacao", "")
              },
              {
                label: "Restrições",
                value: _.get(companyConferi, "bin.restricoes", "")
              },
              {
                label: "Ocorrências",
                value: _.get(companyConferi, "bin.ocorrencias", "")
              },
              {
                label: "Observações",
                value: _.get(companyConferi, "bin.observacoes", "")
              }
            ]}
          />
        )}

        <RenderTable
          title="RESTRIÇÕES DIVERSAS"
          data={[
            {
              label: "Com. de compra/venda",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.comunicacaoVendaAtiva",
                ""
              )
            },
            {
              label: "Restrição Administrativa",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restricaoAdm",
                ""
              )
            },
            {
              label: "Restrição Judiciária",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restricaoJudicial",
                ""
              )
            },
            {
              label: "Restrição Jurídica",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restricaoJudicial",
                ""
              )
            },
            {
              label: "Restrição Financeira",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restricaoFinanceira",
                ""
              )
            },
            {
              label: "Restrição Tributária",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restrTributaria",
                ""
              )
            },
            {
              label: "Restrição roubo/furto",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restricaoFurto",
                ""
              )
            },
            {
              label: "Restrição ambiental",
              value: normalizeData(
                companyConferi,
                "respostaEstadual.restricaoAmbiental",
                ""
              )
            },
            {
              label: "Outras restrições",
              value: normalizeRestrictions(companyConferi)
            }
          ]}
        />

        <RenderHistory
          title="QUEIXA E HISTÓRICO DE ROUBO E FURTO"
          data={
            _.get(companyConferi, "historicoRouboFurto.rouboFurto") ||
            _.get(companyConferi, "bin.historicoRouboFurto.rouboFurto") ||
            []
          }
        />

        {_.get(model, "searchType") === "full" && (
          <>
            <RenderSubSection text="SINISTRO" />
            <Line>
              <Field>
                <strong>
                  {_.get(
                    companyConferi,
                    "respostaSinistro.mensagem",
                    "Sistema indisponível"
                  )}
                </strong>
              </Field>
            </Line>

            <RenderAuction
              title="LEILÃO"
              data={_.get(
                companyConferi,
                "respostaLeilao.historico.leilao",
                []
              )}
            />
          </>
        )}

        {imageLogo && <img src={imageLogo} alt="" className="watermark" />}
        <RenderFooter model={model} page={getPage()} />
      </Container>
    </A4Page>
  );
}

function RenderHistory({ title, data = [] }) {
  data = _.isArray(data) ? data : [data];
  data = data.filter(f => !_.isEmpty(f));
  const hasHistory = _.size(data) > 0;
  return (
    <>
      <RenderSubSection text={title} />
      {!hasHistory ? (
        <Line>
          <Field>
            <strong>NÃO POSSUI HISTÓRICO DE ROUBO/FURTO</strong>
          </Field>
        </Line>
      ) : (
        <Grid columns={3} rows={10} margin={10}>
          {data.map((hist, index) => (
            <div
              className="history"
              key={`history.${_.get(hist, "ocorrencia", "ocurr")}.${index}`}
            >
              <p className="item">
                <span>Ocorrência:</span>
                <strong>{_.get(hist, "ocorrencia")}</strong>
              </p>
              <p className="item">
                <span>Ano:</span>
                <strong>{_.get(hist, "anoBo")}</strong>
              </p>
              <p className="item">
                <span>Orgão de segurança:</span>
                <strong>{_.get(hist, "orgaoSeguranca")}</strong>
              </p>
              <p className="item">
                <span>N° do boletim:</span>
                <strong>{_.get(hist, "numeroBo")}</strong>
              </p>
              <p className="item">
                <span>Município/UF:</span>
                <strong>{_.get(hist, "municipioOcorrencia")}</strong>
              </p>
              <p className="item">
                <span>Data da ocorrência:</span>
                <strong>{_.get(hist, "dataOcorrencia")}</strong>
              </p>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
}

function RenderAuction({ title, data = [] }) {
  data = _.isArray(data) ? data : [data];
  const hasAuction = _.size(data) > 0;
  return (
    <>
      <RenderSubSection text={title} />
      {!hasAuction ? (
        <Line>
          <Field>
            <strong>NÃO POSSUI LEILÃO</strong>
          </Field>
        </Line>
      ) : (
        <Grid columns={3} rows={10} margin={10}>
          {data.map((auction, index) => (
            <div
              className="history"
              key={`auction.${_.get(auction, "ocorrencia", "ocurr")}.${index}`}
            >
              <p className="item">
                <span>Leiloeiro:</span>
                <strong>{_.get(auction, "leiloeiro")}</strong>
              </p>
              <p className="item">
                <span>Data:</span>
                <strong>{_.get(auction, "dataLeilao")}</strong>
              </p>
              <p className="item">
                <span>Comitente:</span>
                <strong>{_.get(auction, "comitente")}</strong>
              </p>
              <p className="item">
                <span>Lote:</span>
                <strong>{_.get(auction, "lote")}</strong>
              </p>
              <p className="item">
                <span>Obs:</span>
                <strong>{_.get(auction, "obs")}</strong>
              </p>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
}

export default Informations;
