import { all, put, call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "~/services/api";
import { Creators as UserActions, Types as UserTypes } from "~/store/modules/users";
import { getError } from  "~/helper";

export function* createOrUpdate({ data, actForm }) {
  try {
    let editing = !!((data && data.id));
    let method = editing ? api.put : api.post;
    let path = editing ? 'update' : 'create';

    yield call(method, `users/${path}`, data);
    yield put(UserActions.listUserRequest(0));

    showSuccess(editing);
    actForm.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    if (actForm) {
      actForm.setSubmitting(false);
    }
  }
}

export function showSuccess(editing) {
  toast.success(`Usuário ${editing ? "atualizado" : "criado"} com sucesso`);
}

export function showError(error) {
  const msg =  getError(error);
  toast.error(msg);
}

export function* listUser({page = 0, term}) {
    try {
      if (page === 0) {
        yield put(UserActions.resetStateUser());
      }
      const response = yield call(api.get, "users/list", { params: {page, term} });
      yield put(UserActions.listUserFinish(response.data));
    } catch (error) {
      showError(error);
    }
}

export default all([
    takeLatest(UserTypes.CREATE_OR_UPDATE_USER, createOrUpdate),
    takeLatest(UserTypes.LIST_USER_REQUEST, listUser),
])
