import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import { Types as TypesAuth } from "~/store/modules/auth";
import { getAvatar } from "~/constants";

/**
 * Action types & Creators
 */
export const { Types, Creators } = createActions({
    updateProfileRequest: ['data', 'actForm'],
    updateAvatarRequest: ['data', 'actForm'],
    updateAvatarSuccess: ['avatar'],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
    profile: null
};

const signInSuccess = (state = INITIAL_STATE, action) => produce(state, draft => { draft.profile = action.user });
const signOut = (state = INITIAL_STATE) => produce(state, draft => { draft.profile = null });
const updateAvatarSuccess = (state = INITIAL_STATE, action) => produce(state, draft => { draft.profile.avatar = getAvatar(action.avatar) });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
    [Types.UPDATE_AVATAR_SUCCESS]: updateAvatarSuccess,
    [TypesAuth.SIGN_IN_SUCCESS]: signInSuccess,
    [TypesAuth.SIGN_OUT]: signOut,
});
