import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import elevation, { elevationTransition } from "~/components/mixins/elevation";
import ripple from "~/components/mixins/ripple";

const mini = css`
  height: 40px;
  width: 40px;
`;

const disabled = css`
  ${elevation(2)};
  &:active {
    ${elevation(8)};
  }
  color: #555 !important;
  background: rgba(0, 0, 0, 0.12) !important;
  pointer-events: none;
`;

const FloatingActionButton = styled.button.attrs({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})`
  overflow: hidden;
  user-select: none;
  outline: none;
  padding: 0;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-appearance: none;
  width: 56px;
  height: 56px;
  font-size: 20px;
  z-index: 1;

  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    overflow: hidden;
  }
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  ${elevationTransition}
  ${ripple()}
  ${props => props.disabled && disabled}
  ${props => props.mini && mini}

  position: fixed;
  color: ${props => (props.color ? props.color : "#fff")};
  background: ${props => (props.background ? props.background : "#f91111")};
  right: ${props => (props.right ? `${props.right}px` : "20px")};
  bottom: ${props => (props.bottom ? `${props.bottom}px` : "20px")};
`;

FloatingActionButton.propTypes = {
  disabled: PropTypes.bool,
  mini: PropTypes.bool
};

FloatingActionButton.defaultProps = {
  disabled: false,
  mini: false
};

export default FloatingActionButton;
