import React from "react";

import { A4Page } from "~/components/print";
import { Container, Grid } from "~/pages/inspection/reports/blend/helper";
import RenderSubSection from "~/pages/inspection/reports/blend/sub-section";
import ImageAuth from "~/components/image";
import { BASE_URL } from "~/constants";
import RenderFooter from "~/pages/inspection/reports/blend/footer";
import _ from "lodash";
import * as imagesLogos from "~/assets/logos";

const urlPhoto = id => `${BASE_URL}/services/photo?id=${id}&filename=`;

const groupLabels = {
  internal: "Fotos Internas",
  external: "Fotos Externas",
  extras: "Fotos extras"
};

const getImageLogo = logo => {
  const keyLogo = `_${logo}`;

  if (!logo) {
    return null;
  }

  return imagesLogos[keyLogo];
};

function Photos({ model = {}, protocol = [], getPage = _.noop }) {
  const { id, photos = [] } = model;
  const accountId = _.get(model, 'customer.parent.id');
  const imageLogo = getImageLogo(accountId);
  const url = urlPhoto(id);
  const grids = [];
  const protocolGroups = _.groupBy(protocol, "location");
  const sorted = {
    internal: new Array(_.size(_.get(protocolGroups, "internal"))),
    external: new Array(_.size(_.get(protocolGroups, "external"))),
    extras: new Array(_.size(_.get(protocolGroups, "extras")))
  };

  _.each(protocolGroups, (groupData, groupKey) => {
    _.times(groupData.length, index => {
      const group = groupData[index];
      const position = _.findIndex(
        photos,
        photo => photo === `${group.field}.jpg`
      );
      if (position !== -1) {
        sorted[groupKey][position] = group;
      }
    });
    sorted[groupKey] = sorted[groupKey].filter(Boolean);
  });

  _.each(sorted, (groupData, groupKey) => {
    for (let index = 0; index < groupData.length; index = index + 18) {
      const partial = groupData.slice(index, index + 18);
      grids.push(
        <A4Page key={`page.${groupKey}.${index}.photos`}>
          <Container>
            <RenderSubSection text={groupLabels[groupKey]} />
            <Grid columns={3} rows={6} margin={10}>
              {partial.map((prot, idx) => {
                return (
                  <ImageAuth
                    key={`page.${groupKey}.${index}.photos.${prot.field}.${idx}`}
                    src={`${url}${prot.field}.jpg`}
                    alt={prot.label}
                  />
                );
              })}
            </Grid>
            
            {imageLogo && <img src={imageLogo} alt="" className="watermark" />}            
            <RenderFooter model={model} page={getPage()} />
          </Container>
        </A4Page>
      );
    }
  });

  return grids;
}

export default Photos;
