import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
     height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, select {
    font: 14px 'Roboto', sans-serif;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  input.error {
    border: 1px solid #f91111 !important;
  }

  div.error {
    display:flex;
    color: #f91111 !important;
    align-self: flex-start;
    margin: 0 0 10px;
  }

  .remove-point-events-datatable {
    pointer-events: "none";
  }
`;
