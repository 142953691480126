import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import _ from "lodash";

/**
 * Actions & creators
 */
export const { Types, Creators } = createActions({
  reportsListCustomersInspectionRequest: ["term", "mapping"],
  reportClosingServiceRequest: ["page", "date", "customerId"],
  reportClosingServiceFinish: ["data"],
  downloadReportClosingServiceRequest: ["date", "customerId"],
  downloadReportClosingServiceRequestFinish: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  loading: false,
  count: 0,
  page: 1,
  data: [],
  customerId: null,
  date: null
};

const reportClosingServiceRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.date = action.date;
    draft.customerId = action.customerId;
    draft.loading = true;
    draft.count = 0;
    draft.page = action.page || 1;
  });

const reportClosingServiceFinish = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.loading = false;
    draft.data = _.get(action, "data.list", []);
    draft.count = _.get(action, "data.count", 0);
  });

const downloadReportClosingServiceRequest = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    draft.date = action.date;
    draft.customerId = action.customerId;
    draft.loading = true;
  });

const downloadReportClosingServiceRequestFinish = (state = INITIAL_STATE) =>
  produce(state, draft => {
    draft.loading = false;
  });

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.REPORT_CLOSING_SERVICE_REQUEST]: reportClosingServiceRequest,
  [Types.REPORT_CLOSING_SERVICE_FINISH]: reportClosingServiceFinish,
  [Types.DOWNLOAD_REPORT_CLOSING_SERVICE_REQUEST]: downloadReportClosingServiceRequest,
  [Types.DOWNLOAD_REPORT_CLOSING_SERVICE_REQUEST_FINISH]: downloadReportClosingServiceRequestFinish
});
