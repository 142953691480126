import { all, takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {Creators as ProfileActions, Types as ProfileTypes } from "~/store/modules/profile";
import api from "~/services/api";
import { getError } from "~/helper";
import _ from "lodash";

export function* updateProfile({ data, actForm }) {
  try {
    const { name, ...rest } = data;
    const profile = Object.assign({ name }, rest.oldPassword ? rest : {});
    yield call(api.put, "/profiles/update-password", profile);
    toast.success("Perfil atualizado com sucesso");
    actForm.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actForm.setSubmitting(false);
  }
}

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* updateAvatar({ data, actForm }) {
  try {
    const formData = new FormData();
    formData.append("file", data);
    const response = yield call(api.post, "profiles/avatar", formData);
    const avatar = _.get(response, 'data.avatar');
    yield put(ProfileActions.updateAvatarSuccess(avatar));
    toast.success("Foto atualizada com sucesso!");
    actForm.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    actForm.setSubmitting(false);
  }
}

export default all([
  takeLatest(ProfileTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(ProfileTypes.UPDATE_AVATAR_REQUEST, updateAvatar),
]);
