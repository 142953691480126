import Report from "./report";

export default Report.create({});

// exemplo de uso
//  const result = await report.show({
//     title: "Inativação",
//     url:  "http://...."
// });
// console.log("Resultado: ", result);
