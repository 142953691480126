import styled from "styled-components";
import { darken } from "polished";
import { Form } from "formik";

export const Inform = styled.div`
  position: relative;
  margin: 0 0 10px;
  background: #d8d8da;
  color: #777;
  border: 0;
  border-radius: 4px;
  padding: 22px 0 8px 10px;

  label {
    position: absolute;
    top: 8px;
    transition: all 200ms;
    opacity: 0.7;
    color: #444;
    font-size: 9.8px;
  }
`;

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  & > div {
    margin-left: 10px;
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-height: ${props => props.maxHeight || "100%"};
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-auto-rows: 90% 1fr;
  width: 100%;
  height: 100%;
  padding: 10px;

  > div {
    padding: ${props => props.padding || "10px"};
    background: #fff;
    border-radius: 2px;
    height: calc(100%);
    overflow: hidden;
  }

  .rdt_Pagination {
    justify-content: center;

    span {
      margin: 0;
    }
  }
  .rdt_TableBody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (max-width: 599px) {
    .rdt_Pagination {
      justify-content: flex-start;

      span {
        margin: 0 10px;
      }
    }
  }
`;

export const CustomerCard = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin: 0px 0px 6px 6px;
  background: ${props =>
    props.active ? "rgba(77, 98, 184, 0.2)" : "rgba(228, 66, 31, 0.1)"};
  border-bottom: 2px solid ${props => (props.active ? "#4d62b8" : "#e4421f")};

  &:hover {
    background: ${darken(0.03, "#fff")};
    cursor: pointer;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: calc(100% - 45px) !important;

  strong {
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 550;
    line-height: 9pt;
  }

  p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    text-transform: lowercase;
    line-height: 13pt;

    &::first-line {
      text-transform: capitalize;
    }
  }

  div {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 10px;
      color: #777;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 7pt;
    }
  }
`;

export const CustomerType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  background: ${props => (props.type === "c" ? "#568bf2" : "#fff")};
  color: ${props => (props.type === "c" ? "#fff" : "#000")};
  font-size: 18px;
  font-weight: bold;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border-radius: 3px;

  .inline {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px 0;

    & > label:first-child {
      margin-right: 10px;
    }
  }

  .radio {
    display: inline-flex;

    input[type="radio"] {
      margin-right: 0.5rem;
    }
  }

  .textarea {
    background: rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 4px;
    padding: 15px 15px;
    color: #444;
    margin: 0 0 10px;
    font: 14px "Roboto", sans-serif;

    &:disabled {
      background: #d8d8da;
      color: #777;
    }

    &:focus {
      background: rgba(249, 185, 48, 0.16);
      color: #444;
    }
  }

  span {
    margin: 0 0 10px;
  }
`;
