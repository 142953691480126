import styled from "styled-components";
import { Form } from "formik";

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  & > svg {
    color: #222;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: #578cf2;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const Content = styled.div`
  display: ${props => (props.visible ? "grid" : "none")};
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-columns: minmax(280px, 1fr);
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  align-content: flex-start;
  padding: 10px;
  width: 100%;
  height: 100%;
  
  @media (max-width: 425px) {
    width: 100%;
    max-width: 290px;
    body {
      overflow-x: visible;
    }
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border-radius: 3px;

  .select {
    margin: 0 0 10px;
  }

  .line {
    flex-direction: row;
    display: flex;
    margin-bottom: 10px;

    & > label {
      margin-right: 10px;
    }
  }

  .input-mask,
  .input-select {
    background: rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #444;
    margin: 0 0 10px;
    font: 14px "Roboto", sans-serif;

    &::placeholder {
      color: rgba(0, 0, 0, 0.7);
    }

    &:disabled {
      background: #d8d8da;
      color: #777;
    }

    &:focus {
      background: rgba(249, 185, 48, 0.16);
      color: #444;
    }
  }

  .input-mask {
    text-transform: upperCase;
  }
`;
