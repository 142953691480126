import Print from "./factory";
import {
    A3Page,
    A4Page,
    A5Page,
} from "./styles";

const Printter = Print.create({});

export {
    A3Page,
    A4Page,
    A5Page,
    Printter
}

// exemplo de uso
// Printter.show({
//     "title": "Primeiro Relatório",
//     "width": "90%",
//     "height": "90%",
//     "component": <A4 layout={"portrait"} />
// });
