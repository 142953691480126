import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Cropper from 'react-easy-crop';
import { useField, useFormikContext } from "formik";
import styled from "styled-components";
import getCroppedImg from "./crop-image";

const FileContainer = styled.div`
  display: flex;
  height: ${props => props.height};
  width: 100%;
  border: 1px dotted rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 5px;
  background: #fff;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #f8f8f8;
    width: 100%;
    border-radius: 3px;
    border: none;

    .crop-container {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
          content: attr(data-content);
          display: flex;
          justify-content: center;
          color: rgba(0,0,0,0.5);
      }
    }

    input {
      display: none;
    }
  }
`;

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const FileImageCrop = ({ ...props }) => {
  const {setFieldValue} = useFormikContext();
  const [field] = useField(props);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({x:0, y: 0});
  const [zoom, setZoom] = useState(1);
  const ref = useRef(null);

  const handleChange = async e => {
    e.persist();
    if (ref.current.files && ref.current.files.length > 0) {
      const urlImage = await readFile(ref.current.files[0]);
      setImage(urlImage);
      setFieldValue(field.name, ref.current.files[0]);
      setCrop({x: 0, y: 0});
      setZoom(1);
      props.onChange && props.onChange(ref.current.files[0]);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    getCroppedImg(image, croppedAreaPixels, 0)
      .then(file => {
        setFieldValue(field.name, file);
      });
  }

  return (
    <FileContainer height={props.height}>
      <label htmlFor={props.name}>
        <div className="crop-container" data-content={!image && props.label}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={props.aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <input
          type="file"
          id={props.name}
          name={props.name}
          accept="image/*"
          ref={ref}
          onChange={handleChange}
        />
      </label>
    </FileContainer>
  );
};

FileImageCrop.propTypes = {
  height: PropTypes.string.isRequired,
  aspect: PropTypes.number.isRequired
}

FileImageCrop.defaultProps = {
  height: "270px",
  aspect: (4/3)
}

export default FileImageCrop;
