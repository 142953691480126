import React, { useEffect, useState, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import DataTable from "react-data-table-component";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { addMonths, subMonths } from "date-fns";
import AsyncSelect from "react-select/async";
import Header from "~/components/header";
import { formats } from "~/helper";
import Spinner from "~/components/spinner";
import {
  Container,
  Content,
  ActionsBar,
  CellContainer,
  Square
} from "./styles";
import { Creators as ReportsActions } from "~/store/modules/reports";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "../reports.css";

registerLocale("pt-BR", ptBR);

const customStyles = {
  control: base => ({
    ...base,
    height: 36,
    width: 250,
    transition: "border-color 0.2s ease-in 0s",
    border: "1px solid #ccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #ccc"
    }
  })
};

export default function ReportClosing() {
  const dispatch = useDispatch();
  const state = useSelector(state => state.reports);
  const now = new Date();
  const [searchDate, setSearchDate] = useState(now);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const columns = useMemo(
    () => [
      {
        name: "Data",
        selector: "createdAt",
        width: "157px",
        cell: row => {
          return (
            <CellContainer>
              <Square inUpdate={row.inUpdate} />
              <strong>
                {formats.dateTimeZone(
                  _.get(row, "createdAt"),
                  "dd/MM/yyyy HH:mm:ss"
                )}
              </strong>
            </CellContainer>
          );
        }
      },
      {
        name: "Placa",
        selector: "board",
        width: "100px",
        cell: row => (
          <strong className="remove-point-events-datatable">
            {formats.board(_.upperCase(row.board))}
          </strong>
        )
      },
      {
        name: "Estrutura",
        selector: "hasStructure",
        hide: "md",
        center: true,
        width: "70px",
        cell: row => (row.hasStructure ? "Sim" : "Não")
      },
      {
        name: "Pintura",
        selector: "hasPaint",
        hide: "md",
        center: true,
        width: "70px",
        cell: row => (row.hasPaint ? "Sim" : "Não")
      },
      {
        name: "Consulta",
        selector: "hasSearch",
        hide: "md",
        center: true,
        width: "70px",
        cell: row => (row.hasSearch ? "Sim" : "Não")
      },
      {
        name: "Cliente",
        selector: row => _.capitalize(_.get(row, "customer.name"))
      },
      {
        name: "Perito",
        hide: "md",
        selector: row => _.capitalize(_.get(row, "technician.name"))
      },
      {
        name: "Solicitante",
        hide: "md",
        width: "150px",
        selector: row => _.capitalize(_.get(row, "requester.name"))
      },
      {
        name: "Vendedor",
        hide: "md",
        width: "150px",
        selector: row => _.capitalize(_.get(row, "salesman"))
      },
      {
        name: "Valor",
        hide: "md",
        width: "100px",
        right: true,
        selector: row => (
          <strong className="remove-point-events-datatable">
            {formats.currency(row.value)}
          </strong>
        )
      }
    ],
    []
  );

  useEffect(() => {
    loadDataReport(1);
    // eslint-disable-next-line
  }, [searchDate, selectedCustomer]);

  const loadDataReport = page => {
    const customerId = _.get(selectedCustomer, "value.id");
    dispatch(
      ReportsActions.reportClosingServiceRequest(page, searchDate, customerId)
    );
  };

  const downloadReport = () => {
    const customerId = _.get(selectedCustomer, "value.id");
    dispatch(
      ReportsActions.downloadReportClosingServiceRequest(searchDate, customerId)
    );
  };

  const handleSearchCustomer = (term, callback) => {
    const mapping = data => {
      callback(
        data.map(customer => ({ label: customer.name, value: customer }))
      );
    };
    dispatch(
      ReportsActions.reportsListCustomersInspectionRequest(term, mapping)
    );
  };

  const handleSelectCustomer = data => {
    let changedCustomer = null;

    if (!_.isEmpty(data)) {
      changedCustomer = {
        label: _.get(data, "name"),
        value: data
      };
    }

    setSelectedCustomer(changedCustomer);
  };

  const handleChangeDate = newDate => {
    setSearchDate(newDate);
  };

  const ReportClosingHeader = memo(({
    loading,
    size,
    dateValue,
    onChangeDate,
    previousDate,
    nextDate
  }) => (
    <Header>
      <h3>Relatório de fechamento mensal ({size})</h3>
      <ActionsBar>
        <Spinner visible={loading} />
        <AsyncSelect
          disabled={state.loading}
          menuPlacement="auto"
          menuPosition="fixed"
          className="select"
          autoFocus={true}
          isSearchable={true}
          isClearable={true}
          loadOptions={handleSearchCustomer}
          defaultValue={selectedCustomer}
          onChange={opt => {
            handleSelectCustomer(_.get(opt, "value"));
          }}
          styles={customStyles}
          placeholder="Informe um cliente"
          noOptionsMessage={props => {
            return _.size(props.inputValue) === 0
              ? "Digite parte do nome do cliente para efetuar a pesquisa!"
              : `Não foi encontrado registros com o termo "${props.inputValue}"`;
          }}
        />
        <MdChevronLeft size={25} onClick={previousDate} />
        <DatePicker
          locale="pt-BR"
          selected={dateValue}
          onChange={handleChangeDate}
          dateFormat={"MM/yyyy"}
          fixedHeight={true}
          showMonthYearPicker={true}
          showFullMonthYearPicker={true}
          showTwoColumnMonthYearPicker={true}
        />
        <MdChevronRight size={25} onClick={nextDate} />
        <button
          type="button"
          onClick={downloadReport}
          disabled={state.loading || _.size(_.get(state, "data", [])) === 0}
        >
          Gerar Excel
        </button>
      </ActionsBar>
    </Header>
  ))

  return (
    <Container maxHeight={_.size(state.count) > 0 && "520px"}>
      <ReportClosingHeader
        size={state.count || 0}
        loading={state.loading}
        dateValue={searchDate}
        onChangeDate={newDate => handleChangeDate(newDate)}
        previousDate={() => handleChangeDate(subMonths(searchDate, 1))}
        nextDate={() => handleChangeDate(addMonths(searchDate, 1))}
      />
      <Content>
        <DataTable
          theme="segvis"
          noHeader
          striped
          highlightOnHover
          pointerOnHover
          dense
          persistTableHead
          showRowHover
          overflowY
          columns={columns}
          data={_.get(state, "data", [])}
          noDataComponent={
            <p>
              <br />
              <strong>Nenhum registro encontrado!</strong>
              <br />
              <br />
            </p>
          }
          page={state.page}
          paginationPerPage={15}
          pagination
          paginationServer
          paginationTotalRows={state.count || 0}
          onChangePage={loadDataReport}
          paginationComponentOptions={{
            noRowsPerPage: true,
            rangeSeparatorText: "de"
          }}
        />
      </Content>
    </Container>
  );
}
