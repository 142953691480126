import React from "react";
import { Switch } from "react-router-dom";
import Route from "~/routes/routes";
import SignIn from "~/pages/signin";
import Kanban from "~/pages/inspection/kanban";
import Inspection from "~/pages/inspection/list";
// import Billing from "~/pages/billing";
import Accounts from "~/pages/accounts";
import Customers from "~/pages/customers";
import Users from "~/pages/users";
import Forbidden from "~/pages/forbidden";
import ReportClosing from "~/pages/reports/closing";

export default function Routes() {
  return (
    // <Route path="/billing" component={Billing} isPrivate acls={['V_BILLINGS']} /> */}
    <Switch>
      <Route path="/" exact component={Kanban} isPrivate acls={['0']} />
      <Route path="/inspections" component={Inspection} isPrivate acls={['3']} />
      <Route path="/accounts" component={Accounts} isPrivate isSysadmin />
      <Route path="/customers" component={Customers} isPrivate acls={['2']} />
      <Route path="/users" component={Users} isPrivate acls={['1']} />
      <Route path="/login" component={SignIn} />
      <Route path="/forbidden" component={Forbidden} />
      <Route path="/report-closing" component={ReportClosing} />
    </Switch>
  );
}
