import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import _ from "lodash";

/**
 * Actions & creators
 */
export const { Types, Creators } = createActions({
    listCustomersInspectionRequest: ['term', 'mapping'],
    createInspectionRequest: ['data', 'actForm'],
    loadInspectionRequest: ['id', 'photos'],
    inspectionRoolbackRequest: ['id'],
    inspectionDataUpdateRequest: ['id'],
    inspectionRemoveTechnicianRequest: ['id'],
    inspectionResetPoolingRequest: [],
    findingServiceRequest: ['page', 'term'],
    findingServiceFinish: ['data'],
    closeServiceUpdateValue: [],
    showModalServiceUpdateValue: ['data'],
    serviceUpdateValue: ['data', 'actForm'],
    resetRestartPolling: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
    loading: false,
    restartPolling: false,
    count: 0,
    page: 1,
    data: [],
    term: null,
    showServiceUpdateValue: false,
    serviceModel: {}
};

const inspectionResetPoolingRequest = (state = INITIAL_STATE) => produce(state, draft => {
    draft.restartPolling = true;
});

const resetRestartPolling = (state = INITIAL_STATE) => produce(state, draft => {
    draft.restartPolling = false;
});

const findingServiceRequest = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.term = action.term;
    draft.loading = true;
    draft.count = 0;
});

const findingServiceFinish = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.loading = false;
    draft.data = _.get(action, "data.list", []);
    draft.count = _.get(action, "data.count", 0);
});

const closeServiceUpdateValue = (state = INITIAL_STATE) => produce(state, draft => {
    draft.showServiceUpdateValue = false;
    draft.serviceModel = {};
});

const showModalServiceUpdateValue = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.showServiceUpdateValue = true;
    draft.serviceModel = _.get(action, 'data', {});
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
    [Types.INSPECTION_RESET_POOLING_REQUEST]: inspectionResetPoolingRequest,
    [Types.FINDING_SERVICE_REQUEST]: findingServiceRequest,
    [Types.FINDING_SERVICE_FINISH]: findingServiceFinish,
    [Types.CLOSE_SERVICE_UPDATE_VALUE]: closeServiceUpdateValue,
    [Types.SHOW_MODAL_SERVICE_UPDATE_VALUE]: showModalServiceUpdateValue,
    [Types.RESET_RESTART_POLLING]: resetRestartPolling,
});
