import styled, { css } from "styled-components";
import { MdClose, MdFullscreen, MdFullscreenExit, MdPrint } from "react-icons/md";

const controlsCss = css`
    position: absolute;
    cursor: pointer;
    z-index: 1;
    padding: 0.1em;
    transition: 0.3s;
    width: 1.6em;
    :hover {
        transform: scale(1.07);
    }
`;

const fullscreen = css`
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};
    opacity: ${props => (props.isOpen ? "1" : "0")};
    transition: 0.1s;
    z-index: 2;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
    height: ${props => (props.children ? "40px" : "0px")};
    visibility: ${props => (props.children ? "visible" : "hidden")};
`;

const ModalContainer = styled.div`
    box-sizing: border-box;
    align-self: center;
    justify-self: center;
    background: ${props => props.background || "#fff"};
    height: ${props => (props.height ? props.height : "20vh")};
    width: ${props => (props.width ? props.width : "50vw")};
    min-height: 150px;
    min-width: 300px;
    position: relative;
    overflow: hidden;
    border-radius: 0.2em;
    transition: 0.3s;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    margin: calc(0.5em + 2vw);
    ${props => props.fullscreen && fullscreen}

    @media (max-width: 768px) {
        width: ${
        props => {
            if (props.fullscreen) return "100vw"
            return (props.width <= 768 ? props.width : "90vw")
        }
        };
    }

    @media (max-width: 425px) {
        width: ${
        props => {
            if (props.fullscreen) return "100vw"
            return (props.width <= 425 ? props.width : "90vw")
        }
        };
    }
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const Close = styled(MdClose)`
    ${controlsCss};
    border-radius: 0.4em;
    top: 0.7em;
    right: 0.5em;
    width: 20px;
    height: 20px;
`;

const FullscreenToggle = styled(MdFullscreen).attrs(props => ({
    as: props.fullscreen === "true" && MdFullscreenExit
  }))`
    ${controlsCss};
    border-radius: 0.4em;
    top: 0.7em;
    right: 2em;
    width: 20px;
    height: 20px;
`;

const PrinterButton = styled(MdPrint)`
    ${controlsCss};
    border-radius: 0.4em;
    top: 0.7em;
    right: 3.7em;
    width: 20px;
    height: 20px;
    color: #000;
`;

const ExtraActionButton = styled.div`
    ${controlsCss};
    border-radius: 0.4em;
    top: 0.7em;
    right: ${props => `${props.right}em`};
    width: 20px;
    height: 20px;
    color: #000;
`;

/**** Print *****/

const Page = styled.div`
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(255,255,255,0.4);

    @media print {
        page {
            margin: 0;
            padding: 0;
            box-shadow: 0;
            height: initial !important;
            overflow: initial !important;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
            -webkit-print-color-adjust: exact;
        }
    }
`;

const Container = styled.div`
    background: rgb(82,86,89);
    padding: 5px;

    @media print {
        margin: 0;
        padding: 0;
        box-shadow: 0;
        height: initial !important;
        overflow: initial !important;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }
`;

const A3Page = styled(Page)`
    width: ${props => (props.layout === "landscape") ? "42cm" : "29.7cm"};
    height: ${props => (props.layout === "landscape") ? "29.7cm" : "42cm"};
    @media print {
        margin: 0;
        padding: 0;
        box-shadow: 0;
        height: initial !important;
        overflow: initial !important;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }
`;

const A4Page = styled(Page)`
    width: ${props => (props.layout === "landscape") ? "29.7cm" : "21cm"};
    height: ${props => (props.layout === "landscape") ? "21cm" : "29.7cm"};
    @media print {
        margin: 0;
        padding: 0;
        box-shadow: 0;
        height: initial !important;
        overflow: initial !important;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }
`;

const A5Page = styled(Page)`
    width: ${props => (props.layout === "landscape") ? "21cm" : "14.8cm"};
    height: ${props => (props.layout === "landscape") ? "14.8cm" : "21cm"};
    @media print {
        margin: 0;
        padding: 0;
        box-shadow: 0;
        height: initial !important;
        overflow: initial !important;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }
`;

export {
    Background,
    ModalContainer,
    Title,
    ModalContent,
    Close,
    FullscreenToggle,
    PrinterButton,
    ExtraActionButton,
    Page,
    Container,
    A3Page,
    A4Page,
    A5Page
};
