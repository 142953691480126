import styled from "styled-components";
import { darken } from "polished";
import { Form } from "formik";

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  & > div {
    margin-left: 10px;
  }
`;

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const Content = styled.div`
  display: ${props => props.visible ? 'grid' : 'none'};
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  align-content: flex-start;
  height: calc(100vh - 60px);
  margin: 10px 10px 0px 0px;
  overflow-y: auto;
`;

export const AccountCard = styled.div`
  display: flex;
  height: 70px;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin: 0px 0px 6px 6px;
  background: ${props => (props.active ? "rgba(77, 98, 184, 0.2)" : "rgba(228, 66, 31, 0.1)")};
  border-bottom: 2px solid ${props => (props.active ? "#4d62b8" : "#e4421f")};

  &:hover {
    background: ${darken(0.03, "#fff")};
    cursor: pointer;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;

  strong {
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 550;
    line-height: 9pt;
  }

  p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    text-transform: lowercase;
    line-height: 13pt;

    &::first-line {
      text-transform: capitalize;
    }
  }

  div {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 10px;
      color: #777;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 7pt;
    }
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border-radius: 3px;

  input:not([type=checkbox]), select {
    background: rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #444;
    margin: 0 0 10px;
    font: 14px 'Roboto', sans-serif;

    &::placeholder {
      color: rgba(0, 0, 0, 0.7);
    }

    &:disabled {
      background: #d8d8da;
      color: #777;
    }

    &:focus {
      background: rgba(249, 185, 48, 0.16);
      color: #444;
    }
  }

  span {
    margin: 0 0 10px;
  }
`;
