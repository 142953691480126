import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import _ from "lodash";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
    createOrUpdateUser: ['data', 'actForm'],
    listUserRequest: ['page', 'term'],
    listUserFinish: ['data'],
    resetStateUser: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
    loading: false,
    hasMore: true,
    count: 0,
    page: 0,
    data: [],
    term: null,
};

const listUserRequest = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.term = action.term;
    draft.loading = true;
    draft.count = 0;
});

const listUserFinish = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.loading = false;
    draft.data = _.get(action, "data.list", []);
    draft.count = _.get(action, "data.count", 0);
});

const resetStateUser = (state = INITIAL_STATE) => produce(state, draft => {
    draft.loading = false;
    draft.count = 0;
    draft.page = 0;
    draft.data = [];
    draft.term = null;
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
    [Types.LIST_USER_REQUEST]: listUserRequest,
    [Types.LIST_USER_FINISH]: listUserFinish,
    [Types.RESET_STATE_USER]: resetStateUser,
});
