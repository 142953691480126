import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import _ from "lodash";
import { MdModeEdit } from "react-icons/md";
import { Formik } from "formik";
import { MaskInput } from "~/components/form";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Modal from "~/components/modal";
import { StyledForm } from "~/pages/inspection/kanban/styles";

const numberMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false
});

const UpdateForm = props => {
  const { service, isOpen, closeModal, handleCancelling, handleSubmit } = props;

  const initialData = {
    id: _.get(service, "id")
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialData}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        handleReset,
        handleSubmit,
        isSubmitting,
        values
      }) => (
        <Modal
          width="450px"
          height="250px"
          allowFullscreen={false}
          open={isOpen}
          hide={closeModal}
          title={
            <>
              <MdModeEdit />
              &nbsp;&nbsp;<span>Valor do Serviço</span>
            </>
          }
          positive={{
            text: isSubmitting ? "Aguarde..." : "Confirma",
            action: handleSubmit,
            disabled: _.size(errors) > 0 || isSubmitting
          }}
          negative={{
            text: "Cancela",
            action: () => handleReset() && handleCancelling(),
            disabled: isSubmitting
          }}
        >
          <StyledForm>
            <MaskInput
              className="input-mask"
              label="Total do serviço (R$)"
              name="value"
              hasError={errors.value && touched.value}
              mask={numberMask}
            />
          </StyledForm>
        </Modal>
      )}
    </Formik>
  );
};

let schema = Yup.object().shape({
  value: Yup.mixed().test("match", "", function(value) {
    const { path, createError } = this;
    if (!value || isNaN(value)) {
      return createError({ path, message: "Informe o valor total do serviço" });
    } else if (value < 100) {
      return createError({
        path,
        message: "Valor total não pode ser menor que R$ 100,00"
      });
    }
    return true;
  })
});

UpdateForm.propTypes = {
  handleCancelling: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

UpdateForm.defaultProps = {
  isOpen: false,
  handleCancelling: () => {}
};

export default UpdateForm;
