import { all, put, call, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "~/services/api";
import { Creators as CustomerActions, Types as CustomerTypes } from "~/store/modules/customers";
import { getError } from  "~/helper";

export function* createOrUpdate({ data, actForm }) {
  try {
    let editing = !!((data && data.id));
    let method = editing ? api.put : api.post;
    let path = editing ? 'update' : 'create';

    yield call(method, `customers/${path}`, data);
    yield put(CustomerActions.listCustomersRequest(0));

    showSuccess(editing);
    actForm.closeModal();
  } catch (error) {
    showError(error);
  } finally {
    if (actForm) {
      actForm.setSubmitting(false);
    }
  }
}

export function showSuccess(editing) {
    toast.success(`Cliente ${editing ? "atualizada" : "criada"} com sucesso`);
}

export function showError(error) {
    const msg =  getError(error);
    toast.error(msg);
}

export function* listCustomers({page = 0, term}) {
    try {
      if (page === 0) {
        yield put(CustomerActions.resetStateCustomer());
      }
      const response = yield call(api.get, "customers/list", { params: {page, term} });
      yield put(CustomerActions.listCustomersFinish(response.data));
    } catch (error) {
      showError(error);
    }
}

export function* cnpjSearch(payload) {
  try {
    const response = yield call(api.get, "customers/external", { params: payload });
    const data = {...response.data, "cnpj_cpf": payload.cnpj};
    yield put(CustomerActions.searchCnpjSuccess(data));
  } catch (error) {
    yield put(CustomerActions.searchCnpjFailed());
    showError(error);
  }
}

export default all([
    takeLatest(CustomerTypes.CREATE_OR_UPDATE_CUSTOMER, createOrUpdate),
    takeLatest(CustomerTypes.LIST_CUSTOMERS_REQUEST, listCustomers),
    takeLatest(CustomerTypes.SEARCH_CNPJ_REQUEST, cnpjSearch),
])
