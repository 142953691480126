import React from "react";
import { all, call, takeLatest, put, delay } from "redux-saga/effects";
import _ from "lodash";
import { toast } from "react-toastify";
import api from "~/services/api";
import { getError } from "~/helper";
import {
  Creators as InspectionActions,
  Types as InspectionTypes
} from "~/store/modules/inspections";

import Report from "~/pages/inspection/reports";
import ReportLink from "~/components/reports";
import { Printter } from "~/components/print";

const protocolPhotos = require("~/pages/inspection/reports/photos-fields.json");

export function* listCustomers({ mapping, page, term }) {
  try {
    const response = yield call(api.get, "services/customers", {
      params: { page, term }
    });
    const list = _.get(response.data, "list", []);
    mapping(list);
  } catch (error) {
    showError(error);
  }
}

export function* create({ data, actForm }) {
  try {
    const params = new FormData();
    const searchType = _.get(data, "searchType");

    params.append("customerId", _.get(data, "customer.id"));
    params.append("board", _.get(data, "board"));
    params.append("file", _.get(data, "file"));
    params.append("hasStructure", _.get(data, "hasStructure", false));
    params.append("hasPaint", _.get(data, "hasPaint", false));
    params.append("hasSearch", _.isEmpty(searchType) ? false : true);
    params.append("searchType", searchType);
    params.append("salesman", _.get(data, "salesman"));

    yield call(api.post, "services/create", params);
    showSuccess("Nova inspeção cadastrada com sucesso!");
    yield delay(500);
    yield put(InspectionActions.inspectionResetPoolingRequest());
    if (actForm) {
      actForm.resetForm();
      actForm.closeModal();
    }
  } catch (error) {
    showError(error);
  } finally {
    actForm && actForm.setSubmitting(false);
  }
}

export function* loadInspection({ id }) {
  try {
    const response = yield call(api.get, "services/report", { params: { id } });
    const {
      data: { value }
    } = response.data;

    if (!value) {
      yield put(InspectionActions.showModalServiceUpdateValue({ id }));
    } else {
      const { data, report, photos } = response.data;
      const { result_link_company_conferi: url } = report;

      if (data.position === "survey") {
        ReportLink.show({ url });
      } else if (
        ["disapproved", "approved", "restrictions"].indexOf(data.position) !==
        -1
      ) {
        Printter.show({
          title: "Impressão",
          width: "90%",
          height: "90%",
          component: (
            <Report
              model={{
                ...data,
                ..._.omit(report, "id"),
                photos
              }}
              photos={protocolPhotos}
            />
          )
        });
      }
    }
  } catch (error) {
    yield put(InspectionActions.closeServiceUpdateValue());
    showError(error);
  }
}

export function* serviceUpdateValue({ data, actForm }) {
  try {
    yield call(api.put, `services/update-value`, data);
    yield loadInspection(data);
  } catch (error) {
    showError(error);
  } finally {
    actForm && actForm.setSubmitting(false);
    yield put(InspectionActions.closeServiceUpdateValue());
  }
}

export function showSuccess(msg) {
  toast.success(msg);
}

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

function* inspectionRoolback({ id }) {
  try {
    yield call(api.get, "services/roolback", { params: { id } });
    showSuccess("Sua solicitação foi efetuada com sucesso!");
    yield delay(500);
    yield put(InspectionActions.inspectionResetPoolingRequest());
  } catch (error) {
    showError(error);
  }
}

function* inspectionDataUpdate({ id }) {
  try {
    yield call(api.get, "services/data-update", { params: { id } });
    showSuccess("Sua solicitação foi efetuada com sucesso!");
    yield delay(500);
    yield put(InspectionActions.inspectionResetPoolingRequest());
  } catch (error) {
    showError(error);
  }
}

function* inspectionRemoveTechnicianRequest({ id }) {
  try {
    yield call(api.get, "services/remove-technician", { params: { id } });
    showSuccess("Sua solicitação foi efetuada com sucesso!");
    yield delay(500);
    yield put(InspectionActions.inspectionResetPoolingRequest());
  } catch (error) {
    showError(error);
  }
}

export function* finding({ page = 0, term }) {
  try {
    const response = yield call(api.get, "services/list", {
      params: { page, term }
    });
    yield put(InspectionActions.findingServiceFinish(response.data));
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(InspectionTypes.LIST_CUSTOMERS_INSPECTION_REQUEST, listCustomers),
  takeLatest(InspectionTypes.CREATE_INSPECTION_REQUEST, create),
  takeLatest(InspectionTypes.LOAD_INSPECTION_REQUEST, loadInspection),
  takeLatest(InspectionTypes.INSPECTION_ROOLBACK_REQUEST, inspectionRoolback),
  takeLatest(
    InspectionTypes.INSPECTION_DATA_UPDATE_REQUEST,
    inspectionDataUpdate
  ),
  takeLatest(
    InspectionTypes.INSPECTION_REMOVE_TECHNICIAN_REQUEST,
    inspectionRemoveTechnicianRequest
  ),
  takeLatest(InspectionTypes.FINDING_SERVICE_REQUEST, finding),
  takeLatest(InspectionTypes.SERVICE_UPDATE_VALUE, serviceUpdateValue)
]);
