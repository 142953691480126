import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import AuthLayout from "~/pages/_layouts/auth";
import DefaultLayout from "~/pages/_layouts/default";
import { store } from "~/store";
import get from "lodash/get";

export default function RouteWrapper({
  component: Component,
  acls,
  isPrivate,
  isSysadmin,
  ...rest
}) {
  const { auth, profile } = store.getState();
  const signed = !!auth.signed;
  const profileData = get(profile, 'profile', {});
  const permissions = get(profileData, 'permissions', []);
  const isOk = acls.length === 0 || permissions.filter(p => acls.includes(p)).length > 0;
  let Layout = AuthLayout;

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (!isOk) {
    return <Redirect to="/forbidden" />;
  }

  if (signed) {
    Layout = DefaultLayout;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isSysadmin: PropTypes.bool,
  acls: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isSysadmin: false,
  acls: []
};
