import React from "react";
import _ from "lodash";
import RenderSubSection from "~/pages/inspection/reports/blend/sub-section";
import {Row, Field} from "~/pages/inspection/reports/blend/helper";

const RenderTable = ({icon, title, data=[], columns=2}) => {
    const tmp = data.filter(f => !_.isEmpty(f.value));
    const rows = [];
    const keyTitle = _.kebabCase(title);
    let drawBackground = true;

    for (let index = 0; index < tmp.length; index = index + columns) {
        let partial = tmp.slice(index, index + columns);
        rows.push(
            <Row key={`${keyTitle}.${index}`}>
                {
                    partial.map((part, i) => {
                        let {label, value} = part;
                        value = ["null", "undefined", null, undefined].includes(value) ? "" : value;
                        return (
                            <Field key={`${keyTitle}.${index}.${i}`} divider={i > 0}>
                                <span>{label}:</span>
                                <strong>{value}</strong>
                            </Field>
                        )
                    })
                }
            </Row>
        )
        drawBackground = !drawBackground;
    }
    return (
        <>
            <RenderSubSection
                icon={icon}
                text={title} />
            { rows }
        </>
    );
}

export default RenderTable;
