import { createActions, createReducer } from "reduxsauce";
import produce from "immer";
import _ from "lodash";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
    searchAccountCnpjRequest: ['cnpj'],
    searchAccountCnpjSuccess: ['data'],
    searchAccountCnpjFailed: [],
    createOrUpdateAccount: ['data', 'actForm'],
    listAccountsRequest: ['page', 'term'],
    listAccountsFinish: ['data'],
    resetStateAccount: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
    loading: false,
    hasMore: true,
    count: 0,
    page: 0,
    data: [],
    term: null,
    cnpjData: {},
    cnpjSearching: false
};


const listAccountsRequest = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.term = action.term;
    draft.hasMore = true;
    draft.loading = true;
    draft.count = 0;
});

const listAccountsFinish = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.loading = false;
    draft.data = _.concat(draft.data, _.get(action, "data.list", []));
    draft.hasMore = _.get(action, "data.hasMore", false);
    draft.count = _.get(action, "data.count", 0);
    draft.page = (draft.page + 1);
});

const resetStateAccount = (state = INITIAL_STATE) => produce(state, draft => {
    draft.loading = false;
    draft.hasMore = false;
    draft.count = 0;
    draft.page = 0;
    draft.data = [];
    draft.term = null;
    draft.cnpjData = {};
    draft.cnpjSearching = false;
});

const searchAccountCnpjRequest = (state = INITIAL_STATE) => produce(state, draft => {
    draft.cnpjSearching = true;
});

const searchAccountCnpjSuccess = (state = INITIAL_STATE, action) => produce(state, draft => {
    draft.cnpjData = _.get(action, "data", {});
    draft.cnpjSearching = false;
});

const searchAccountCnpjFailed = (state = INITIAL_STATE) => produce(state, draft => {
    draft.cnpjSearching = false;
});

/**
 * Reducers
 */
export default createReducer(INITIAL_STATE, {
    [Types.LIST_ACCOUNTS_REQUEST]: listAccountsRequest,
    [Types.LIST_ACCOUNTS_FINISH]: listAccountsFinish,
    [Types.SEARCH_ACCOUNT_CNPJ_REQUEST]: searchAccountCnpjRequest,
    [Types.SEARCH_ACCOUNT_CNPJ_SUCCESS]: searchAccountCnpjSuccess,
    [Types.SEARCH_ACCOUNT_CNPJ_FAILED]: searchAccountCnpjFailed,
    [Types.RESET_STATE_ACCOUNT]: resetStateAccount,
});
