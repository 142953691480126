import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import logoGray from "~/assets/logo-gray.svg";
import logo from "~/assets/logo.png";

const Container = styled.div`
  display: ${props => (props.visible ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 60px);

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size};
    height: ${props => props.size};
    padding: attr(width * 60%);
    border-radius: 50%;
    background: #ffffff7d;
    margin: 40px;
  }

  img {
    width: 70%;
    height: 70%;
    border: none;
    margin: 40px;
    color: rgba(0, 0, 0, 0.2);
    fill: rgba(0, 0, 0, 0.2);
    opacity: 0.2
  }

  h3 {
    color: #999 !important;
  }
`;

function EmptyState(props) {
  const { size = "100px" } = props;

  return (
    <Container visible={props.visible} size={size}>
      <div className="circle">
        <img src={logo} alt="" title={size} />
      </div>
      <h3>{props.text}</h3>
    </Container>
  );
}

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool
};

EmptyState.defaultProps = {
  text: "",
  visible: false
};

export default EmptyState;
