import get from "lodash/get";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const formats = {
  board: str => {
    let cleaned = ("" + str).replace(/\W/g, "");
    cleaned = cleaned.replace(/(\w{3})(\d{1})(\d|\w{1})(\d{2})/, "$1-$2$3$4");
    return cleaned.toUpperCase();
  },
  phone: str => {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{2})(\d{4}|\d{5})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  },
  cep: str => {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{2})(\d{3})(\d{3})$/);
    if (match) {
      return match[1] + "." + match[2] + "-" + match[3];
    }
    return null;
  },
  cnpj: str => {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return (
        match[1] +
        "." +
        match[2] +
        "." +
        match[3] +
        "/" +
        match[4] +
        "-" +
        match[5]
      );
    }
    return null;
  },
  cpf: str => {
    let cleaned = onlyNumbers(str);
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
    if (match) {
      return match[1] + "." + match[2] + "." + match[3] + "-" + match[4];
    }
    return null;
  },
  cnpj_cpf: str => {
    let cleaned = onlyNumbers(str);
    if (size(cleaned) === 11) {
      return formats.cpf(cleaned);
    } else {
      return formats.cnpj(cleaned);
    }
  },
  dateTimeZone: (date, mask = "dd/MM/yyyy") => {
    if (isEmpty(date)) return "";
    return format(zonedTimeToUtc(date, "America/Sao_Paulo"), mask);
  },
  date: (date, mask = "dd/MM/yyyy") => {
    if (!date) {
      return "";
    }
    return format(date, mask);
  },
  fill: (value = "", size = 1, fillString = "") => {
    return String(value).padStart(size, fillString);
  },
  currency: (value = 0, locale = "pt-BR", currencyCode = "BRL") => {
    value = value || 0;
    return parseFloat(value).toLocaleString(locale, {
      style: "currency",
      currency: currencyCode
    });
  },
  percent: (value = 0, locale = "pt-BR") => {
    return parseFloat(value).toLocaleString(locale, { style: "percent" });
  },
  decimal: (value = 0, locale = "pt-BR", decimalPlaces = 2) => {
    return parseFloat(value).toLocaleString(locale, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    });
  },
  number: (value = 0, locale = "pt-BR") => {
    return parseFloat(value).toLocaleString(locale);
  }
};

export const onlyNumbers = value => {
  if (!value) return null;
  return value.replace(/[^0-9]/g, "");
};

export const getError = payload => {
  return (
    get(payload, "response.data.message") ||
    get(payload, "error.message") ||
    "Falha na comunicação com o servidor. Tente novamente!"
  );
};
