import { all, call, takeLatest, put } from "redux-saga/effects";
import _ from "lodash";
import { toast } from "react-toastify";
import api from "~/services/api";
import { getError } from "~/helper";
import {
  Creators as ReportsActions,
  Types as ReportsTypes
} from "~/store/modules/reports";
import { formats } from "~/helper";

export function* listCustomers({ mapping, term }) {
  try {
    const response = yield call(api.get, "services/customers", {
      params: { term }
    });
    const list = _.get(response.data, "list", []);
    mapping(list);
  } catch (error) {
    showError(error);
  }
}

export function showError(error) {
  const msg = getError(error);
  toast.error(msg);
}

export function* reportClosing({ page = 0, date, customerId }) {
  try {
    const response = yield call(api.get, "services/report-closing", {
      params: { page, date, customerId }
    });
    yield put(ReportsActions.reportClosingServiceFinish(response.data));
  } catch (error) {
    showError(error);
  }
}

export function* downloadReportClosingService({ date, customerId }) {
  try {
    const response = yield call(api.get, "services/export-report-closing", {
      responseType: "blob",
      params: { date, customerId }
    });
    const filename = `relatorio-fechamento-mes-${formats.date(
      date,
      "dd-MM-yyyy"
    )}.xlsx`;
    api.download(response, filename);
    yield put(ReportsActions.downloadReportClosingServiceRequestFinish());
  } catch (error) {
    showError(error);
  }
}

export default all([
  takeLatest(
    ReportsTypes.REPORTS_LIST_CUSTOMERS_INSPECTION_REQUEST,
    listCustomers
  ),
  takeLatest(ReportsTypes.REPORT_CLOSING_SERVICE_REQUEST, reportClosing),
  takeLatest(
    ReportsTypes.DOWNLOAD_REPORT_CLOSING_SERVICE_REQUEST,
    downloadReportClosingService
  )
]);
