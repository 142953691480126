import React from "react";
import { Container, Label, Info } from "./styles";
import get from "lodash/get";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import { formats } from "~/helper";

// const colors = {
//   "bin": "#fac37b",
//   "leilao": "#69dae9",
//   "historico": "#7796d5",
//   "precificador": "#e8db64",
//   "sinistro": "#e49ce0",
//   "completa": "#94d48f"
// };

const Board = (text) => {
  if (isEmpty(text)) return {"letter": "", "numeric": ""};
  text = text.replace(/[^a-zA-Z0-9+]/g, "");
  return {
    "letter": text.slice(0, 3),
    "numbers": text.slice(3)
  }
}

const ColorType = (inUpdate) => {
  if (inUpdate) return "#e6467f";
  return "#53d449";
}

const ToolTip = ({hasStructure, hasPaint, hasSearch, board, requesterName, customerName, technicianName="", createAt}) => {
  return `Placa do veículo:\n\t ${upperCase(get(board, 'letter'))}-${get(board, 'numbers')}`
    .concat(`\nCliente:\n\t ${capitalize(lowerCase(customerName))}`)
    .concat(`\nSolicitante:\n\t ${capitalize(lowerCase(requesterName))}`)
    .concat(`\nVistoriador:\n\t ${capitalize(lowerCase(technicianName))}`)
    .concat(`\nData da solicitação:\n\t ${createAt}`)
    .concat(`\nServiço(s):`)
    .concat(`\n\tEstrutural:\t ${hasStructure ? 'Sim' : 'Não'}`)
    .concat(`\n\tPintura:\t ${hasPaint ? 'Sim' : 'Não'}`)
    .concat(`\n\tConsulta:\t ${hasSearch ? 'Sim' : 'Não'}`)
}

export default function Card({ data, handleClick }) {
  const board = Board(get(data, 'board'));
  const createAt = formats.dateTimeZone(get(data, 'createdAt'), 'dd/MM/yyyy HH:mm:ss');
  const requesterName = get(data, 'requester.name');
  const customerName = get(data, 'customer.name');
  const technicianName = get(data, 'technician.name') || '';
  const inUpdate = get(data, 'inUpdate', false);
  const color = ColorType(inUpdate);
  const hasStructure = get(data, 'hasStructure');
  const hasPaint = get(data, 'hasPaint');
  const hasSearch = get(data, 'hasSearch');

  return (
    <Container
      color={color}
      onClick={(e) => handleClick(data)}
      title={
        ToolTip({hasStructure, hasPaint, hasSearch, board, requesterName, customerName, technicianName, createAt})
      }
    >
      <Label color={color}>
        <span>{board.letter}</span>
        <span>{board.numbers}</span>
      </Label>
      <Info>
        <strong>{customerName}</strong>
        <p>{requesterName}</p>
        <span>{technicianName} {createAt}</span>
      </Info>
    </Container>
  );
}
