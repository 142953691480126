import _ from "lodash";
import styled from "styled-components";

export const normalizeData = (data, fieldpath) => {
    const value = _.get(data, fieldpath);
    if (_.isEmpty(value)) return "Nada consta";
    return value;
};

export const normalizeRestrictions = data => {
    const str = _(data.respostaEstadual)
        .pick([
            "restricoes_01",
            "restricoes_02",
            "restricoes_03",
            "restricoes_04",
            "respostaEstadual.restricoes_05"
        ])
        .values()
        .filter(f => !_.isEmpty(f) && _.lowerCase(f) !== "sem restricao")
        .value()
        .join(",  ");
    return _.isEmpty(str) ? "Nada consta" : str;
};

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font: 13px "Roboto", sans-serif;
    overflow: auto;

    & .watermark {
        position: absolute;
        display: block;
        display: flex;
        align-self: center;
        width: 60%;
        opacity: 10%;
        top: 30%;
    }
`;

export const Divider = styled.div`
    height: ${props => (props.size ? `${props.size}px` : "2px")};
    width: 100%;
    background: #578cf2;
    margin-top: 1px;
`;

export const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    background: ${props => props.background};
    min-height: 15px;
    border-bottom: 0.8px solid #ccc;

    & > div {
        flex: 1;
    }

    span {
        min-width: 140px;
    }
`;

export const Field = styled.div`
    display: flex;
    flex-direction: ${props => (props.direction ? props.direction : "row")};
    align-items: ${props =>
        props.direction && props.direction === "column"
            ? "flex-start"
            : "center"};
    justify-content: ${props =>
        props.direction && props.direction === "column"
            ? "center"
            : "flex-start"};
    background: ${props => props.background || "transparent"};
    color: ${props => props.color};
    font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
    padding: 0px 2px;
    border-left: ${props => (!props.divider ? "none" : "2px solid #fff")};
    min-height: ${props => (props.minHeight ? props.minHeight : "10px")};

    svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        .title {
            width: 22px;
            height: 22px;
        }
    }

    span,
    strong {
        font-size: 10px;
    }

    span {
        margin-right: 5px;
    }

    .term {
        text-align: justify;
        text-justify: inter-word;
    }

    .capitalize {
        text-transform: lowercase;
        &::first-line {
            text-transform: capitalize;
        }
    }

    .title {
        font-size: 14px;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(
        ${props => (props.columns ? props.columns : 2)},
        1fr
    );
    grid-template-rows: repeat(
        ${props => (props.rows ? props.rows : 2)},
        auto-fit
    );
    grid-gap: ${props => (props.margin ? `${props.margin}px` : "2px")};

    align-items: center;
    justify-content: center;

    position: relative;

    img {
        width: 100%;
        height: 160px;
        object-fit: contain;
        object-fit: cover;
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .history {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-left: 1px solid #ccc;

        &:first-child {
            border: none;
            margin: none;
        }

        .item {
            margin-left: 5px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2px;
            font-size: 11px;
        }
    }
`;

export const Line = styled.div`
    display: flex;
    justify-content: space-between;
    background: ${props => props.background};
    margin-top: 1px;
`;

export const Sign = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
`;

export const ColumnStructure = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        &:first-child {
            margin-right: 15px;
        }
    }
`;
