export const audi = require('./audi.png')
export const bmw = require('./bmw.png')
export const chery = require('./chery.png')
export const citroen = require('./citroen.png')
export const dodge = require('./dodge.png')
export const ferrari = require('./ferrari.png')
export const fiat = require('./fiat.png')
export const ford = require('./ford.png')
export const gm = require('./gm.png')
export const honda = require('./honda.png')
export const hyundai = require('./hyundai.png')
export const iveco = require('./iveco.png')
export const jac = require('./jac.png')
export const jaguar = require('./jaguar.png')
export const jeep = require('./jeep.png')
export const kia = require('./kia.png')
export const landRover = require('./landRover.png')
export const lexus = require('./lexus.png')
export const lifan = require('./lifan.png')
export const lr = require('./lr.png')
export const mBenz = require('./mBenz.png')
export const mini = require('./mini.jpeg')
export const mmc = require('./mmc.png')
export const nissan = require('./nissan.png')
export const peugeot = require('./peugeot.png')
export const porsche = require('./porsche.png')
export const reboque = require('./reboque.png')
export const renault = require('./renault.png')
export const scania = require('./scania.png')
export const ssangyong = require('./ssangyong.png')
export const suzuki = require('./suzuki.png')
export const toyota = require('./toyota.png')
export const triumph = require('./triumph.png')
export const troller = require('./troller.png')
export const volvo = require('./volvo.png')
export const vw = require('./vw.png')
export const yamaha = require('./yamaha.png')
