import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  background: #f7f8f9;
  border-radius: 3px;
  margin: 8px;
  padding: 10px;
  border-top: 1px solid ${props => props.color};
  box-shadow: 0 1px 4px 0 rgba(138, 138, 138, 0.4);
  display: flex;
  align-items: center;
  justify-items: center;

  &:hover {
    background: ${darken(0.03, "#f8f8fa")};
    cursor: pointer;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 5px 0 2px 10px;

  strong {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    max-width: 200px;
    text-transform: lowercase;

    &::first-line {
      text-transform: capitalize;
    }
  }

  p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    color: #333;
    max-width: 200px;
    text-transform: lowercase;

    &::first-line {
      text-transform: capitalize;
    }
  }

  span {
    font-size: 10px;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    max-width: 200px;
  }

  @media (max-width: 425px) {
    strong {
      max-width: 150px;
    }

    p {
      max-width: 150px;
    }

    span {
      max-width: 150px;
    }
  }
`;

export const Label = styled.span`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
`;
