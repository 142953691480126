import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 15px 15px 15px 0;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #ccddfe7d, #f7f8f9);
`;
