import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import search from "~/assets/search.svg";
import useEventListener from "~/hooks/use-event-listener";

const Container = styled.div`
  display: flex;

  input {
    width: 25px;
    height: 35px;
    border: none;
    padding: 5px 30px 5px 10px;
    border-radius: 6px;
    font-size: 10pt;
    color: #111;
    background: #fff url(${search}) no-repeat 13px center;
    box-shadow: 0 0 1.5px #999;

    -webkit-transition: background 0.45s ease;
    -moz-transition: background 0.45s ease;
    -ms-transition: background 0.45s ease;
    -o-transition: background 0.45s ease;
    transition: background 0.45s ease;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      width: 100%;
      background: #f8f8f8 url(${search}) no-repeat 92% center;
      cursor: text;
    }

    &::-webkit-input-placeholder {
      color: #222;
    }

    &:-moz-placeholder {
      color: #222;
    }

    &::-moz-placeholder {
      color: #222;
    }

    &:-ms-input-placeholder {
      color: #222;
    }

    img {
      height: 44px;
      width: 45px;
      border-radius: 50%;
      margin: 0 15px;
    }
  }
`;

function InputSearch({ onSearch, incrementalSearch, ...rest }) {
  const [term, setTerm] = useState("");
  const handler = useCallback(onKeyDown, [setTerm]);

  useEventListener("keydown", handler);

  useEffect(() => {
    if (incrementalSearch && term && term.length > 0) {
      onSearch(term);
    }
  }, [term, incrementalSearch, onSearch]);

  function handleChange(e) {
    setTerm(e.target.value);
  }

  function onKeyDown(event) {
    const charCode = event.which || event.keyCode;

    if (charCode === 27) {
      setTerm("");
      onSearch("");
      event.target.blur();
    }
    
    if (charCode === 13) {
      setTerm(event.target.value);
      onSearch(event.target.value);
      event.target.focus();
    }
  }

  return (
    <Container>
      <input type="text" value={term} onChange={handleChange} {...rest} />
    </Container>
  );
}

InputSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

InputSearch.defaultProps = {
  placeholder: "Pesquisa..."
};

export default memo(InputSearch);
