import React, { useState } from "react";
import Card from "~/components/card";
import { Container } from "./styles";
import size from "lodash/size";
import groupBy from "lodash/groupBy";
import get from "lodash/get";
import styled from "styled-components";
import Popover, { ArrowContainer } from "react-tiny-popover";
import { ContextMenuTrigger } from "react-contextmenu";

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f8f8f8;
  width: 250px;
  height: 350px;
  overflow: auto;
  background: #fffefe;

  .header {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 0;
    margin-left: 10px;
    color: #555;
  }

  .line {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #777;
    padding: 15px 15px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: lowercase;
      max-width: 180px;

      &::first-line {
        text-transform: capitalize;
      }
    }

    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    &:hover {
      background: #e8e8e8;
    }
  }
`;

const Groups = ({ data }) => {
  return Object.values(data).map((value, idx) => (
    <Group rows={value} key={`groups.${idx}`} />
  ));
};

const Group = ({ rows }) => {
  const { label, data } = rows;
  return (
    <>
      <div className="header" key={label}>
        {label}
      </div>
      {data.map(row => (
        <div className="line" key={row.id} title={row.label}>
          <span>{row.label}</span>
          <strong>{row.count}</strong>
        </div>
      ))}
    </>
  );
};

const FilterContainer = ({ children, data, isOpen, close }) => (
  <Popover
    isOpen={isOpen}
    position={"bottom"}
    onClickOutside={close}
    containerStyle={{
      boxShadow: "0px 15px 15px #aaa, 0px 0px 10px -30px #ccc"
    }}
    content={({ position, targetRect, popoverRect }) => (
      <ArrowContainer
        position={position}
        targetRect={targetRect}
        popoverRect={popoverRect}
        arrowColor={"#777"}
        arrowSize={10}
      >
        <Filter>
          <Groups data={data} />
        </Filter>
      </ArrowContainer>
    )}
  >
    {children}
  </Popover>
);

const groups = (fields, data) => {
  const result = {};

  fields.forEach(value => {
    const { field, label } = value;

    result[field] = result[field] || {
      label: label,
      data: []
    };

    const groupped = groupBy(data, `${field}.id`);

    Object.values(groupped).forEach((group, idx) => {
      result[field]["data"].push({
        id: get(group, `[0].${field}.id`) || `group.${field}.id.${idx}`,
        label: get(group, `[0].${field}.name`) || `Não atribuído`,
        count: size(group)
      });
    });
  });

  return result;
};

export default function Cards({
  name,
  label,
  iconType,
  data,
  handleClick,
  menuContextId,
  handleMenuContextClick
}) {
  const IconType = iconType;
  const [isOpenFilter, setOpenFilter] = useState(false);
  const grps = groups(
    [
      { field: "customer", label: "Cliente" },
      { field: "technician", label: "Técnico responsável" },
      { field: "requester", label: "Quem solicitou o serviço" }
    ],
    data
  );

  function BuildCard({
    keyNameContextMenu,
    keyNameCard,
    name,
    menuContextId,
    data,
    handleClick,
    handleMenuContextClick
  }) {
    if (menuContextId) {
      return (
        <ContextMenuTrigger
          key={`${menuContextId}.${keyNameContextMenu}`}
          id={menuContextId}
          name={name}
          holdToDisplay={200}
          attributes={{
            id: data.id,
            board: data.board
          }}
        >
          <Card
            key={`${menuContextId}.${keyNameCard}`}
            data={data}
            handleClick={handleClick}
            handleMenuContextClick={handleMenuContextClick}
          />
        </ContextMenuTrigger>
      );
    }
    return (
      <Card
        key={keyNameCard}
        data={data}
        handleClick={handleClick}
        handleMenuContextClick={handleMenuContextClick}
      />
    );
  }

  return (
    <Container key={`container.cards.${name}`}>
      <header>
        <h2>
          {label}
          {size(data) > 0 && (
            <FilterContainer
              key={name}
              data={grps}
              isOpen={isOpenFilter}
              close={() => setOpenFilter(false)}
            >
              <span onClick={() => setOpenFilter(true)}>({size(data)})</span>
            </FilterContainer>
          )}
        </h2>
        <span>
          <IconType size={20} />
        </span>
      </header>
      <div className="cardList">
          {data.map((card, idx) => (
            <BuildCard
              key={`.mnu-context-card.${idx}`}
              keyNameContextMenu={
                "mnu-context-card." + name + "." + idx + "." + card.board
              }
              keyNameCard={name + "." + idx + "." + card.board + "." + card.id}
              name={name + "." + idx + "." + card.board + "." + card.id}
              menuContextId={menuContextId}
              data={card}
              handleClick={handleClick}
              handleMenuContextClick={handleMenuContextClick}
            />
          ))}
      </div>
    </Container>
  );
}
