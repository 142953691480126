import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 5px 10px;
  font: 13px "Roboto", sans-serif;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 110px;
    height: 77px;
  }

  .supervisor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;

    p {
      text-transform: lowercase;
      &::first-line {
        text-transform: capitalize;
      }
    }
  }

  img.seal {
    width: 100px;
    height: 89px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(255, 255, 255, 0.7);

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    background: #384854;

    svg {
      width: 14px;
      height: 14px;
    }

    .title {
      font-size: 14px;
      margin-left: 5px;
    }
  }
`;

const Divider = styled.div`
  height: ${props => (props.size ? `${props.size}px` : "2px")};
  width: 100%;
  background: #578cf2;
  margin-top: 1px;
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => (props.color ? props.color : "rgba(0,0,0,0.8)")};
  /* margin-top: 5px; */

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    background: ${props => (props.background ? props.background : "#bfbcbb")};

    svg {
      width: 16px;
      height: 16px;
    }

    .title {
      font-size: 13px;
      margin-left: 5px;
    }
  }
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${props => props.background};
  margin-top: 1px;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  background: ${props => props.background};
  min-height: 23px;

  & > div {
    flex: 1;
  }

  em {
    min-width: 150px;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props =>
    props.direction && props.direction === "column" ? "flex-start" : "center"};
  justify-content: ${props =>
    props.direction && props.direction === "column" ? "center" : "flex-start"};
  background: ${props => props.background || "transparent"};
  color: ${props => props.color};
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
  padding: 0px 2px;
  border-left: ${props => (!props.divider ? "none" : "2px solid #fff")};

  min-height: ${props => (props.minHeight ? props.minHeight : undefined)};

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    .title {
      width: 22px;
      height: 22px;
    }
  }

  span,
  strong {
    line-height: 21px;
  }

  em {
    font-size: 11px;
    margin-right: 5px;
  }

  .term {
    text-align: justify;
    text-justify: inter-word;
  }

  .capitalize {
    text-transform: lowercase;
    &::first-line {
      text-transform: capitalize;
    }
  }

  .title {
    font-size: 16px;
  }
`;

const Board = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 110px;
    height: 42.9px;
  }

  .top {
    font-size: 6px;
    top: 2px;
    text-transform: uppercase;
    position: absolute;
  }

  .center {
    font-size: 14px;
    top: 16px;
    font-weight: bold;
    position: absolute;
    text-transform: uppercase;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => (props.columns ? props.columns : 2)},
    1fr
  );
  grid-template-rows: repeat(
    ${props => (props.rows ? props.rows : 2)},
    auto-fit
  );
  grid-gap: ${props => (props.margin ? `${props.margin}px` : "2px")};

  align-items: center;
  justify-content: center;

  position: relative;

  img {
    width: 100%;
    height: 160px;
    object-fit: contain;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .history {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-left: 1px solid #ccc;

    &:first-child {
      border: none;
      margin: none;
    }

    .item {
      margin-left: 5px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2px;
      font-size: 11px;
    }
  }
`;

const Sign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
`;

const CarContainer = styled.div`
  position: relative;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Markup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  margin: 0;
  z-index: 9;

  div {
    background: ${props => props.background};
    width: auto;
    height: 28px;
    padding: 5px;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
  }

  p {
    width: auto;
    height: 21px;
    font-size: 12px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export {
  Container,
  Header,
  Section,
  SubSection,
  Line,
  Field,
  Board,
  Divider,
  Row,
  Grid,
  Sign,
  CarContainer,
  Markup
};
