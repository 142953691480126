import _ from "lodash";
import React from "react";
import { A4Page } from "~/components/print";
import { formats } from "~/helper";
import RenderFooter from "~/pages/inspection/reports/blend/footer";
import RenderHeader from "~/pages/inspection/reports/blend/header";
import {
  ColumnStructure,
  Container,
  Field,
  Line,
  Sign
} from "~/pages/inspection/reports/blend/helper";
import RenderSubSection from "~/pages/inspection/reports/blend/sub-section";
import RenderTable from "~/pages/inspection/reports/blend/table";
import * as imagesLogos from "~/assets/logos";

const fieldsLabels = require("./fields-labels.json");

const getImageLogo = logo => {
  const keyLogo = `_${logo}`;

  if (!logo) {
    return null;
  }

  return imagesLogos[keyLogo];
};

const dictionary = {
  original: "Original/Dentro do padrão",
  damaged: "Danificada",
  divergent: "Divergente",
  fake: "Etiqueta falsa",
  without_visualization: "Sem visualização",
  implanted_core: "Miolo implantado",
  absent: "Ausente (passível de reg. DETRAN)",
  unreadable: "Ilegível/sem impressão",
  transplanted: "Transplantada",
  not_required: "Não obrigatória",
  true: "Sim",
  false: "Não",
  not_record: "Sem gravação",
  not_original_record: "Gravação não original"
};

function Structure({ model = {}, getPage = _.noop }) {
  const accountId = _.get(model, 'customer.parent.id');
  const imageLogo = getImageLogo(accountId);

  return (
    <A4Page>
      <Container>
        <RenderHeader position={model.position} accountId={accountId} />

        <RenderTable
          title="LAUDO DE VISTORIA VEICULAR"
          data={[
            {
              label: "Data da vistoria",
              value: formats.dateTimeZone(
                _.get(model, "surveyAt"),
                "dd/MM/yyyy HH:mm"
              )
            },
            {
              label: "Número",
              value: formats.fill(_.get(model, "id"), 10, "0")
            },
            {
              label: "Data da solicitação",
              value: formats.dateTimeZone(
                _.get(model, "createdAt"),
                "dd/MM/yyyy HH:mm"
              )
            },
            {
              label: "Vistoriador",
              value: _.upperCase(_.get(model, "technician.name"))
            }
          ]}
        />

        <RenderTable
          title="DADOS DO CLIENTE"
          data={[
            {
              label: "Nº Documento",
              value: formats.cnpj_cpf(_.get(model, "customer.identify"))
            },
            {
              label: "Nome",
              value: _.upperCase(_.get(model, "customer.name"))
            }
          ]}
        />

        <RenderSubSection text="STATUS DO VEÍCULO" />
        <br />

        <RenderConformity model={model} />
        <br />

        <RenderSubSection text="OBSERVAÇÃO DO VEÍCULO" />
        <Line>
          <Field>
            <strong>{_.get(model, "notes", "Não possui observação")}</strong>
          </Field>
        </Line>
        <br />

        <RenderSubSection text="TERMO DE CIÊNCIA DO CONTRATANTE" />
        <Line>
          <Field>
            <em className="term">
              Eu, contratante desta pesquisa, declaro ter lido e compreendido as
              informações acima descritas, tendo ciência que todas as
              informações prestadas no corpo desta pesquisa visam exclusivamente
              COLABORAR com o processo de averiguação de procedência do veículo,
              servindo apenas como uma ferramenta de ANÁLISE PRÉVIA e não como
              elemento de decisão para comercialização do veículo. As
              informações exibidas nesta pesquisa são as que se encontram
              disponíveis na data e hora da consulta às bases, sites e pacotes
              de dados ora contratados e somente será utiliza único e
              exclusivamente por mim CONTRATANTE sabendo que estou vedado da
              divulgação da mesma para terceiros, e isento a Autolist de toda e
              qualquer responsabilidade caso haja divergência de informação,
              informo ainda que utilizo a consulta para fins esta s cos e tenho
              conhecimento que existem casos de não informado e divergências em
              relação as bases on-lines, podendo os registros serem apagados ou
              apresentarem divergências ou ainda possuírem informações
              incompletas ou excluídas, como por exemplo apontamentos de
              registros de leilão, sinistro e demais informações históricas, que
              podem ser alteradas, baixadas, excluídas e/ou não informadas.
            </em>
          </Field>
        </Line>

        <Sign>
          <div className="underline">
            ____________________________________________________________________________________
          </div>
          <strong>Vistoriador: {_.get(model, "technician.name")}</strong>
        </Sign>

        {imageLogo && <img src={imageLogo} alt="" className="watermark" />}
        <RenderFooter model={model} page={getPage()} />
      </Container>
    </A4Page>
  );
}

function RenderConformity({ model = {} }) {
  return (
    <ColumnStructure>
      <div>
        {[
          "cond_chassi_number",
          "cond_motor_number",
          "cond_car_change_number",
          "cond_board",
          "cond_bodywork",
          "cond_history",
          "cond_crlv",
          "cond_tarjeta_chassi_motor",
          "cond_tarjeta_chassi_flap",
          "cond_tarjeta_chassi_floor"
        ].map((field, index) => (
          <div key={`checklist.1.${field}.${index}`}>
            <RenderChecklist
              keyName={field}
              item={_.get(model, `checklist.${field}`, {})}
            />
            <br />
          </div>
        ))}
      </div>
      <div>
        {[
          "cond_chassi_record_glass_front",
          "cond_chassi_record_glass_front_door_left",
          "cond_chassi_record_glass_rear_door_left",
          "cond_chassi_record_glass_side_rear_left",
          "cond_chassi_record_glass_watchman",
          "cond_chassi_record_glass_side_rear_right"
        ].map((field, index) => (
          <div key={`checklist.3.${field}.${index}`}>
            <RenderChecklist
              keyName={field}
              item={_.get(model, `checklist.${field}`, {})}
            />
            <br />
          </div>
        ))}

        {[
          "cond_chassi_record_glass_rear_door_right",
          "cond_chassi_record_glass_front_door_right"
        ].map((field, index) => (
          <div key={`checklist.4.${field}.${index}`}>
            <RenderChecklist
              keyName={field}
              item={_.get(model, `checklist.${field}`, {})}
            />
            <br />
          </div>
        ))}

        {["belt_date"].map((field, index) => (
          <div key={`checklist.2.${field}.${index}`}>
            <RenderChecklist
              withValue={true}
              keyName={field}
              item={_.get(model, `checklist.${field}`, {})}
            />
            <br />
          </div>
        ))}

        <RenderSubSection
          text={_.get(fieldsLabels, "belt_date_compatibility")}
        />
        <Line>
          <Field>
            <strong>
              {_.toString(
                _.get(
                  model,
                  "checklist.belt_date_compatibility.year_auto_ok"
                ) || false
              ) === "true"
                ? "Sim"
                : "Não"}
            </strong>
          </Field>
        </Line>
        <br />
      </div>
    </ColumnStructure>
  );
}

function RenderChecklist({ keyName, item = {}, withValue = false }) {
  return (
    Object.keys(item).length > 0 && (
      <>
        <RenderSubSection text={_.upperCase(_.get(fieldsLabels, keyName))} />
        <RenderItemChecklist
          withValue={withValue}
          keyName={keyName}
          item={item}
        />
      </>
    )
  );
}

function RenderItemChecklist({ keyName, item = {}, withValue }) {
  const BuildField = ({ keyPath, itemKey, data, index }) => {
    const label = _.get(fieldsLabels, `${keyPath}.${itemKey}`, "");
    if (!withValue) {
      return (
        <Field>
          <strong>
            {index + 1} - {label || dictionary[data[itemKey]]}
          </strong>
        </Field>
      );
    }
    return (
      <Field>
        <strong>
          {label} - {data[itemKey]}
        </strong>
      </Field>
    );
  };

  return Object.keys(item).map(
    (key, index) =>
      item[key] && (
        <Line key={`${keyName}.item.${key}.${index}`}>
          <BuildField
            index={index}
            itemKey={key}
            keyPath={keyName}
            data={item}
          />
        </Line>
      )
  );
}

export default Structure;
