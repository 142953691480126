import React from "react";
import styled from "styled-components";
import Board from "~/pages/inspection/reports/blend/board";
import _ from "lodash";
import * as imagesSeals from "~/assets/seals";
import * as imagesBrands from "~/assets/brands";
import * as imagesLogos from "~/assets/logos";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 5px 5px;

  img.logo {
    // width: 70.5px;
    // height: 75px;
    max-width: 180px;
    max-height: 90px;
  }

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px !important;
  }

  .position {
    font-weight: 600;
    font-size: 14px;
    margin-left: 15px;
  }

  img.brand {
    height: 50px;
    margin: 10px;
  }

  img.seal {
    width: 65px;
    height: 58px;
  }
`;

const positions = {
  disapproved: "Não Conforme",
  approved: "Aprovado",
  restrictions: "Aprovado c/Apontamentos"
};

const BRANDS = {
  GM: "gm",
  CHEVROLET: "gm",
  FORD: "ford",
  TOYOTA: "toyota",
  JEEP: "jeep",
  RENAULT: "renault",
  HONDA: "honda",
  NISSAN: "nissan",
  "M.BENZ": "mBenz",
  FIAT: "fiat",
  HYUNDAI: "hyundai",
  TROLLER: "troller",
  JAGUAR: "jaguar",
  "LAND ROVER": "landRover",
  VW: "vw",
  DODGE: "dodge",
  AUDI: "audi",
  PEUGEOT: "peugeot",
  CHEV: "gm",
  SSANGYONG: "ssangyong",
  MMC: "mmc",
  LR: "landRover",
  CITROEN: "citroen",
  KIA: "kia",
  SCANIA: "scania",
  MITSUBISHI: "mmc",
  CAOACHERY: "chery",
  BMW: "bmw",
  VOLVO: "volvo",
  LEXUS: "lexus",
  PORSCHE: "porsche",
  SUZUKI: "suzuki",
  YAMAHA: "yamaha",
  LIFAN: "lifan",
  CHERY: "chery",
  IVECO: "iveco",
  JAC: "jac",
  "I/TOYOTA": "toyota",
  "I/CHEV": "gm",
  MINI: "mini",
  "I/KIA": "kia",
  "I/LR": "landRover",
  "I/HYUNDAI": "hyundai",
  "I/FORD": "ford",
  TRIUMPH: "triumph",
  "I/VW": "vw",
  "I/NISSAN": "nissan",
  "I/M.BENZ": "mBenz",
  "I/CITROEN": "citroen",
  "I/AUDI": "audi",
  "I/PORSCHE": "porsche",
  "I/BMW": "bmw",
  "LR DISCOVERY TD6 HSE 7": "landRover",
  "RENAULT / DUSTER 16 E CVT": "renault",
  FERRARI: "ferrari",
  "I/HONDA": "honda",
  "I/PEUGEOT": "peugeot",
  "I/MMC": "mmc",
  "I/VOLVO": "volvo",
  "I/RENAULT": "renault",
  "I/CHEVROLET": "gm",
  "I/FIAT": "fiat",
  "I/SSANGYONG": "ssangyong",
  "I/MINI": "mini",
  "I/GM": "gm"
};

const getImageBrand = brand => {
  brand = (brand || "").trim();
  const keyBrand = BRANDS[brand];

  if (_.isEmpty(brand) || _.isEmpty(keyBrand)) {
    return null;
  }

  return imagesBrands[keyBrand];
};

const getImageLogo = logo => {
  const keyLogo = `_${logo}`;

  if (!logo) {
    return null;
  }

  return imagesLogos[keyLogo];
};

const getImageSeal = (id, seal) => {
  const keySeal = `${seal}_${id}`;
  return imagesSeals[keySeal];
};

const RenderHeader = ({ accountId, position, board, brand }) => {
  const imageBrand = getImageBrand(brand);
  const imageLogo = getImageLogo(accountId);
  
  const seal = ["approved", "restrictions"].includes(position)
  ? 'approval'
  : 'disapproval';

  const imageSeal = getImageSeal(accountId, seal);

  return (
    <Header>
      {imageLogo && <img src={imageLogo} alt="" className="logo" />}
      <div className="status">
        {board && <Board value={board} />}
        <div className="position">Status: {positions[position]}</div>
        {imageBrand && <img src={imageBrand} alt="" className="brand" />}
      </div>
      {imageSeal && <img src={imageSeal} alt="" className="seal" />}
    </Header>
  );
};

export default RenderHeader;
