import React from "react";

import Informations from "~/pages/inspection/reports/informations";
import Structure from "~/pages/inspection/reports/structure";
import Photos from "~/pages/inspection/reports/photos";
import Paint from "~/pages/inspection/reports/paint";
import SignPage from "~/pages/inspection/reports/sign";

function Report({ model = {}, photos = [] }) {
  const { hasStructure = false, hasPaint = false, hasSearch = false } = model;

  let page = 0;

  const getPage = () => {
    page++;
    return page;
  };

  return (
    <>
      {hasSearch && <Informations model={model} getPage={getPage} />}
      {hasStructure && <Structure model={model} getPage={getPage} />}
      {hasStructure && (
        <Photos
          model={model}
          photos={photos}
          protocol={photos}
          getPage={getPage}
        />
      )}
      {hasPaint && <Paint model={model} getPage={getPage} />}
      <SignPage model={model} getPage={getPage} />
    </>
  );
}

export default Report;
