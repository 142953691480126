import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {
  Background,
  Title,
  Container,
  Content,
  Footer,
  Close,
  FullscreenToggle
} from "./styles";

function Modal({
  open,
  title,
  hide,
  positive,
  negative,
  width,
  height,
  allowFullscreen,
  children
}) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  function handleHide() {
    setIsFullscreen(false);
    hide();
  }

  function handleIsFullscreen() {
    setIsFullscreen(!isFullscreen);
  }

  function buildAction(typeAction, data = {}) {
    const { text, action, disabled } = data;

    if (!text) return null;

    if (typeAction === "positive") {
      return (
        <button
          type="submit"
          className="positive"
          onClick={action}
          disabled={!!disabled}
        >
          {text}
        </button>
      );
    }
    return (
      <button
        type="button"
        className="negative"
        onClick={() => action() & handleHide()}
        disabled={!!disabled}
      >
        {text}
      </button>
    );
  }

  function build() {
    if (!open) return null;

    return (
      <Background isOpen={open}>
        <Container fullscreen={isFullscreen} height={height} width={width}>
          <Close onClick={handleHide} />
          {allowFullscreen === true && (
            <FullscreenToggle
              fullscreen={isFullscreen.toString()}
              onClick={handleIsFullscreen}
            />
          )}
          <Title>{title}</Title>
          <Content>{children}</Content>
          <Footer>
            {buildAction("negative", negative)}
            {buildAction("positive", positive)}
          </Footer>
        </Container>
      </Background>
    );
  }

  return ReactDOM.createPortal(build(), document.body);
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  allowFullscreen: PropTypes.bool,
  title: PropTypes.object,
  hide: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  positive: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool
  }),
  negative: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.func,
    disabled: PropTypes.bool
  })
};

Modal.defaultProps = {
  open: false,
  allowFullscreen: false,
  width: "80vw",
  height: "80vh"
};

export default Modal;
