import React, { useState, memo } from "react";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Container, Content, Toolbar, Profile } from "./styles";
import ImageAuth from "~/components/image";
import { getAvatarDefault } from "~/constants";
import { Creators as ProfileActions } from "~/store/modules/profile";
import MenuProfile from "./menu-profile";
import ProfileModals from "~/pages/profile/modals";

function Header({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector(state => get(state, 'profile.profile', {}));
  const name = get(profile, 'name', '');
  const avatar = get(profile, 'avatar', '');
  const accountName = get(profile, 'account.name', '');
  const [avatarFrmIsOpen, setAvatarFrmIsOpen] = useState(false);
  const [pwdFrmIsOpen, setPwdFrmIsOpen] = useState(false);

  function handleUpdatePassword(data, actions) {
    actions.closeModal = () => setPwdFrmIsOpen(false);
    dispatch(ProfileActions.updateProfileRequest(data, actions));
  }

  function handleUpdateAvatar(data, actions) {
    const { avatar } = data;
    actions.closeModal = () => setAvatarFrmIsOpen(false);
    dispatch(ProfileActions.updateAvatarRequest(avatar, actions));
  }

  function handleMenuAction(action) {
    switch(action) {
      case 'avatar':
        setAvatarFrmIsOpen(true);
        break;
      case 'pwd':
        setPwdFrmIsOpen(true);
        break;
      default:
    }
  }

  return (
    <Container>
      <ProfileModals
        profileData={profile}
        passwordFrmIsOpen={pwdFrmIsOpen}
        closeModalPassword={() => setPwdFrmIsOpen(false)}
        handleUpdatePassword={handleUpdatePassword}
        avatarData={{avatar}}
        avatarFrmIsOpen={avatarFrmIsOpen}
        closeModalAvatar={() => setAvatarFrmIsOpen(false)}
        handleUpdateAvatar={handleUpdateAvatar}
      />

      <Content>
        <Toolbar>
          {
            React.Children.map(children, child => {
              return React.cloneElement(child, {})
            })
          }
        </Toolbar>
        <Profile>
          <div>
            <strong title={name}>{name}</strong>
            <span>{accountName}</span>
          </div>
          <MenuProfile onAction={handleMenuAction}>
            <ImageAuth
              withoutLoading={true}
              src={avatar}
              defaultImage={getAvatarDefault()}
            />
          </MenuProfile>
        </Profile>
      </Content>
    </Container>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired
};

export default memo(Header);
