import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Container = styled.label`
  display: flex;

  input[type=checkbox] {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const Checkbox = (props) => (
    <Container>
        <Field
          type="checkbox"
          id={props.id}
          name={props.name}
        />
        {props.label && <span>{props.label}</span>}
    </Container>
);

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    hasError: PropTypes.bool,
}

export default Checkbox;
