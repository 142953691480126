import React from "react";
import styled from "styled-components";

const SubSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${props =>  props.color ? props.color : "#fff"};
    background: ${props =>  props.background ? props.background : "#568bf2"};
    padding: 2px;
    font-size: 12px;
    font-weight: 600;
`;

const RenderSubSection = ({text, background, color}) => {
    return (
        <SubSection background={background} color={color}>
            {text}
        </SubSection>
    )
};

export default RenderSubSection;
