import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import size from "lodash/size";
import useModal from "~/hooks/use-modal";
import useScroll from "~/hooks/use-scroll";
import useWindowSize from "~/hooks/use-window-size";
import Header from "~/components/header";
import Spinner from "~/components/spinner";
import { Search } from "../../components/form";
import EmptyState from "~/components/empyt-state";
import FloatingActionButton from "~/components/floatbutton";
import { Container, Content, AccountCard, Information, ActionsBar } from "./styles";
import { MdAdd, MdEdit, MdAccountBalanceWallet } from "react-icons/md";
import { formats } from "~/helper";
import AccountForm from "./form";
import { Creators as AccountActions } from "~/store/modules/accounts";
import _ from "lodash";

export default function Account() {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const {isOpen, openModal, closeModal} = useModal();
  const [account, setAccount] = useState({});
  const [title, setTitle] = useState();
  const state = useSelector(state => state.accounts);

  useScroll({ id: "accounts-list", handleOnScroll, hasMore: state.hasMore });

  useEffect(() => {
    if (state.cnpjData) {
      setAccount(state.cnpjData);
    }
  }, [state.cnpjData]);

  useEffect(() => {
    const reset = () => dispatch(AccountActions.resetStateAccount());
    return () => { reset(); }
  }, [dispatch]);

  function handleActions(evt, data, act) {
    evt.preventDefault();
    const clone = _.clone(data);
    if (_.isEmpty(clone.address)) {
      clone.address = {};
    }
    setAccount(clone);
    configTitle(act);
    openModal();
  }

  function handleOnScroll() {
    dispatch(AccountActions.listAccountsRequest(state.page, state.term));
  }

  function searching(text) {
    dispatch(AccountActions.listAccountsRequest(0, text));
  }

  function handleOnSubmit(data, actions) {
    actions.closeModal = () => {
      setAccount({});
      closeModal();
    }
    dispatch(AccountActions.createOrUpdateAccount(data, actions));
  }

  const AccountHeader = memo(({loading, count}) => {
    return (
      <Header>
        <h3>Gestão de contas ({count})</h3>
        <ActionsBar>
          <Spinner visible={loading} />
          <Search onSearch={searching} />
        </ActionsBar>
      </Header>
    )
  })

  function configTitle(act) {
    const text = (act === "add") ? "Adicionar" : "Editar";
    setTitle(
      <>
        {act === "add" ? <MdAccountBalanceWallet /> : <MdEdit />}
        &nbsp;&nbsp;<span>{text} conta</span>
      </>
    );
  }

  function ButtonAdd(props) {
    return (
      <FloatingActionButton
        background="#578cf2"
        onClick={(e) => handleActions(e, {"active": true}, "add")}
      >
        <MdAdd />
      </FloatingActionButton>
    )
  }

  const AccountsList = ({list = []}) => {
    return list.map(row => (
      <AccountCard
        key={`${row.id}`}
        active={row.active}
        onClick={(e) => handleActions(e, row, "edit")}
      >
        <Information>
          <strong>{row.identify && formats.cnpj(row.identify)}</strong>
          <p>{row.name}</p>
          <div>
            <span>{formats.dateTimeZone(row.createdAt)}</span>
            <span>{row.phone && formats.phone(row.phone)}</span>
          </div>
        </Information>
      </AccountCard>
    ));
  };

  return (
    <Container maxHeight={size(state.data) > 0 && "520px"}>
      <AccountHeader loading={state.loading} count={state.count} />
      <ButtonAdd />
      { isOpen &&
        <AccountForm
          title={title}
          data={account}
          isOpen={isOpen}
          closeModal={closeModal}
          handleOnSubmit={handleOnSubmit}
          searchCnpj={(value) => {
            dispatch(AccountActions.searchAccountCnpjRequest(value));
          }}
          cnpjSearching={state.cnpjSearching}
        />
      }
      { size(state.data) === 0 &&
        <EmptyState
          text="nenhum cliente ainda"
          visible={size(state.data) === 0}
          size={windowSize.width <= 360 ? "130px" : "180px"}
          />
      }
      <Content id="accounts-list" visible={size(state.data) > 0}>
        <AccountsList list={state.data} />
      </Content>
    </Container>
  );
}
