import React, { useState, useEffect, memo } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logo from "~/assets/logo.png";
import { Container, Sidebar, Aside, Profile } from "./styles";
import {
  MdViewQuilt,
  MdViewList,
  MdAccountBalance,
  // MdCardGiftcard,
  MdPortrait,
  MdPerson,
  MdExitToApp,
  MdReceipt
} from "react-icons/md";
import { Creators as AuthActions } from "~/store/modules/auth";
import ImageAuth from "~/components/image";
import { getAvatarDefault } from "~/constants";
import get from "lodash/get";
import MenuProfile from "~/components/header/menu-profile";
import ProfileModals from "~/pages/profile/modals";
import { Creators as ProfileActions } from "~/store/modules/profile";
import ReactTooltip from 'react-tooltip';

function Menu() {
  const dispatch = useDispatch();
  const profile = useSelector(state => get(state, 'profile.profile', {}));
  const avatar = get(profile, 'avatar', '');
  const [avatarFrmIsOpen, setAvatarFrmIsOpen] = useState(false);
  const [pwdFrmIsOpen, setPwdFrmIsOpen] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const actions = [
    {
      route: "/",
      acls: ['0'],
      icon: MdViewQuilt,
      title: "Acompanhamento de vistorias"
    },
    {
      route: "/inspections",
      acls: ['3'],
      icon: MdViewList,
      title: "Pesquisas de vistorias"
    },
    // {
    //   route: "/billing",
    //   acls: ['4'],
    //   icon: MdCardGiftcard,
    //   title: "Histórico de pagamentos"
    // },
    {
      route: "/accounts",
      onlySysadmin: true,
      icon: MdAccountBalance,
      title: "Gestão de contas"
    },
    {
      route: "/customers",
      acls: ['2'],
      icon: MdPerson,
      title: "Gestão de clientes"
    },
    {
      route: "/users",
      acls: ['1'],
      icon: MdPortrait,
      title: "Gestão de usuários"
    },
    {
      route: "/report-closing",
      acls: ['5'],
      icon: MdReceipt,
      title: "Relatório de fechamento mensal"
    },
  ];
  const permissions = get(profile, 'permissions', []);
  const userIsSysadmin = get(profile, 'is_sysadmin', false);
  const access = actions.filter(act => {
    const acls = act.acls || []
    const hasAccess = (permissions.filter(p => acls.includes(p)).length > 0)
    const onlySysadmin = (act.onlySysadmin && userIsSysadmin)
    return hasAccess || onlySysadmin
  });

  function handleSignOut() {
    dispatch(AuthActions.signOut());
  }

  function handleMenuAction(action) {
    switch(action) {
      case 'avatar':
        setAvatarFrmIsOpen(true);
        break;
      case 'pwd':
        setPwdFrmIsOpen(true);
        break;
      default:
    }
  }

  function handleUpdatePassword(data, actions) {
    actions.closeModal = () => setPwdFrmIsOpen(false);
    dispatch(ProfileActions.updateProfileRequest(data, actions));
  }

  function handleUpdateAvatar(data, actions) {
    const { avatar } = data;
    actions.closeModal = () => setAvatarFrmIsOpen(false);
    dispatch(ProfileActions.updateAvatarRequest(avatar, actions));
  }

  return (
    <Container>
      <Sidebar>
        <img src={logo} alt="Segvis" />
        <nav>
          <ul>
            <div>
              <ReactTooltip />
              {
                access.map((act, index) => (
                  <div key={`menu-${index}`}>
                    <NavLink 
                      to={act.route} 
                      exact
                      data-tip={act.title}
                      data-effect="solid"
                      data-place="right"
                      data-class="app-side-menu">
                      <li>
                        <act.icon size={24} title={act.title} />
                      </li>
                    </NavLink>
                  </div>
                ))
              }
            </div>
          </ul>
        </nav>
      </Sidebar>
      <Aside>
        <Profile>
          <ProfileModals
            profileData={profile}
            passwordFrmIsOpen={pwdFrmIsOpen}
            closeModalPassword={() => setPwdFrmIsOpen(false)}
            handleUpdatePassword={handleUpdatePassword}
            avatarData={{avatar}}
            avatarFrmIsOpen={avatarFrmIsOpen}
            closeModalAvatar={() => setAvatarFrmIsOpen(false)}
            handleUpdateAvatar={handleUpdateAvatar}
          />
          <MenuProfile onAction={handleMenuAction}>
            <ImageAuth
              src={avatar}
              defaultImage={getAvatarDefault()}
            />
          </MenuProfile>
        </Profile>
        <ul className="menu-bottom">
          <li>
            <MdExitToApp size={24} onClick={handleSignOut} />
          </li>
        </ul>
      </Aside>
    </Container>
  );
}

export default memo(Menu);
