import { all } from "redux-saga/effects";
import auth from "./auth/sagas";
import profile from "./profile/sagas";
import users from "./users/sagas";
import accounts from "./accounts/sagas";
import customers from "./customers/sagas";
import inspections from "./inspections/sagas";
import reports from "./reports/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    profile,
    users,
    accounts,
    customers,
    inspections,
    reports,
  ]);
}
