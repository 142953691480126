import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import Modal from "~/components/modal";
import { StyledForm } from './styles';
import { Input, Checkbox, Select, MaskInput } from "../../components/form";
import Divider from "~/components/divider";
import useWindowSize from "~/hooks/use-window-size";
import Spinner from "~/components/spinner";
import { PHONE_MASK, CNPJ_MASK, CEP_MASK, UFS } from '~/constants';
import { onlyNumbers } from "~/helper";


function AccountForm({
  title,
  data,
  isOpen,
  closeModal,
  handleOnSubmit,
  handleCancelling,
  searchCnpj,
  cnpjSearching
}) {

  const windowSize = useWindowSize();

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={schema}
      initialValues={data}
      onSubmit={handleOnSubmit}
      initialTouched={{
        'permissions': true
      }}
    >
      {
        ({
          errors,
          isValid,
          status,
          touched,
          handleSubmit,
          handleReset,
          isSubmitting,
          values
        }) => (
            <Modal
                width="400px"
                height={windowSize.height < 470 ? "90%" : "85%"}
                allowFullscreen={true}
                open={isOpen}
                hide={closeModal}
                title={cnpjSearching ? (<><Spinner visible={cnpjSearching} /> consultando CNPJ... </>) : title}
                positive={{
                    text: isSubmitting ? "Aguarde..." : "Confirma",
                    action: handleSubmit,
                    disabled: !isValid || isSubmitting
                }}
                negative={{
                    text: "Cancela",
                    action: () => handleReset() && handleCancelling(),
                    disabled: isSubmitting
                }}
            >
            <StyledForm>
                <Checkbox
                    name="active"
                    label="Ativo"
                    disabled={cnpjSearching}
                />
                <MaskInput
                    disabled={values.id}
                    mask={CNPJ_MASK}
                    name="identify"
                    placeholder="CNPJ"
                    hasError={errors.identify && touched.identify}
                    onBlur={(e) => {
                        let value = onlyNumbers(e.target.value);
                        if (value && value.length === 14 && searchCnpj) {
                            searchCnpj(value);
                        }
                    }}/>
                <Input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    hasError={errors.name && touched.name}
                    disabled={cnpjSearching} />
                <Input
                    type="tradeName"
                    name="tradeName"
                    placeholder="Nome fantasia"
                    disabled={cnpjSearching} />
                <MaskInput
                    name="phone"
                    mask={PHONE_MASK}
                    placeholder="Telefone"
                    disabled={cnpjSearching} />

                {
                  !values.id && (
                    <>
                      <Divider label="Dados do Administrador" />
                      <Input
                          type="text"
                          name="adminUsername"
                          placeholder="Nome do usuário"
                          hasError={values.id && errors.adminUsername && touched.adminUsername}
                          disabled={cnpjSearching} />
                      <Input
                          type="text"
                          name="adminEmail"
                          placeholder="E-mail do usuário"
                          hasError={values.id && errors.adminEmail && touched.adminEmail}
                          disabled={cnpjSearching} />
                    </>
                  )
                }

                <Divider label="Endereço" />
                <Input
                    type="text"
                    name="address.address"
                    placeholder="Avenida, rua, ..."
                    disabled={cnpjSearching} />
                <Input
                    type="text"
                    name="address.number"
                    disabled={cnpjSearching}
                    placeholder="Número" />
                <Input
                    type="text"
                    name="address.complements"
                    disabled={cnpjSearching}
                    placeholder="Complemento" />
                <Input
                    type="text"
                    name="address.block"
                    disabled={cnpjSearching}
                    placeholder="Bairro" />
                <MaskInput
                    mask={CEP_MASK}
                    name="address.zip_code"
                    disabled={cnpjSearching}
                    placeholder="CEP" />
                <Input
                    type="text"
                    name="address.city"
                    disabled={cnpjSearching}
                    placeholder="Cidade" />
                <Select
                    name="address.state"
                    options={
                        {
                            defaults: {value: "", label: "Selecione uma UF"},
                            values: Object.keys(UFS).map(key => ({"value": key, "label": UFS[key]}))
                        }
                    }
                    disabled={cnpjSearching}
                />

                <Divider label="Company Conferi" />
                <Input
                    type="text"
                    name="extras.company_conferi.user"
                    disabled={cnpjSearching}
                    placeholder="Código" />
                <Input
                    type="password"
                    name="extras.company_conferi.pwd"
                    disabled={cnpjSearching}
                    placeholder="Senha" />

              {status && status.msg && <div>{status.msg}</div>}
            </StyledForm>
          </Modal>
        )
      }
    </Formik>
  );
}

const schema = Yup.object().shape({
  id: Yup.string(),
  identify: Yup.string()
    .required("É necessário informar o CNPJ"),

  name: Yup.string()
    .min(4, "Verifique se a 'razão social' da empresa está correta").required("Informar a 'razão social'"),

  adminUsername: Yup.string()
    .when('id', {
      is: val => !val,
      then: Yup.string().required('Informe o nome do usuário')
    }),

  adminEmail: Yup.string()
    .when('id', {
      is: val => !val,
      then: Yup.string().email("Informe um e-mail válido").required("Informe o e-mail do usuário")
    })
});

AccountForm.propTypes = {
  title: PropTypes.node,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func,
  handleCancelling: PropTypes.func
};

AccountForm.defaultProps = {
  isOpen: false,
  handleOnSubmit: () => {},
  handleCancelling: () => {}
};

export default AccountForm;
