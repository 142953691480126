import React from "react";
import ReactDOM from "react-dom";
import { Background, Container, Title, Content, Close } from "./styles";
import IFrame from "~/components/frame";

class Report extends React.Component {
  state = {
    isOpen: false,
    reportProps: {}
  };

  resolve = null;

  static create(props = {}) {
    const el = document.createElement("div");
    document.body.appendChild(el);
    return ReactDOM.render(<Report reportProps={props} />, el);
  }

  handleCancel = () => {
    this.setState({ isOpen: false, reportProps: {} });
    this.resolve(false);
  };

  handleConfirm = () => {
    this.setState({ isOpen: false, reportProps: {} });
    this.resolve(true);
  };

  show = (props = {}) => {
    const reportProps = { ...this.props.reportProps, ...props };
    this.setState({ isOpen: true, reportProps });
    return new Promise(res => {
      this.resolve = res;
    });
  };

  render() {
    const { isOpen, reportProps } = this.state;
    const { title = "", url, width, height } = reportProps;

    if (!url || url.length === 0 || !isOpen) return <></>

    return (
      <Background isOpen={isOpen}>
        <Container height={height} width={width}>
          <Close onClick={this.handleCancel} />
          <Title>{title}</Title>
          <Content>
            <IFrame src={url} />
          </Content>
        </Container>
      </Background>
    );
  }
}

export default Report;
