import React from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import logoSign from "~/assets/logo-sign.png";
import { Creators as AuthActions } from "~/store/modules/auth";
import InputGroup from "~/components/form/input.group";

export default function SignIn() {
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    unit: Yup.string().required("Informe a unidade de trabalho"),
    username: Yup.string().required("O usuário é obrigatório"),
    password: Yup.string().required("A senha é obrigatória")
  });

  function handleOnSubmit(data, actions) {
    dispatch(AuthActions.signInRequest(data, actions));
  }

  return (
    <>
      <img
        src={logoSign}
        alt="Inpecta"
      />
      <Formik
        validationSchema={schema}
        initialValues={{unit: "", username: "", password: ""}}
        onSubmit={handleOnSubmit}
      >
        {
          ({
            errors,
            isValid,
            touched,
            handleSubmit,
            isSubmitting,
            values
          }) => (
            <Form>
              <InputGroup
                type="text"
                name="unit"
                label="Unidade de trabalho"
                value={values.unit}
                hasError={errors.unit && touched.unit}
              />
              <InputGroup
                type="username"
                name="username"
                label="Usuário"
                value={values.username}
                hasError={errors.username && touched.username}
              />
              <InputGroup
                type="password"
                name="password"
                label="Senha"
                value={values.password}
                hasError={errors.password && touched.password}
              />
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={!isValid || isSubmitting}
              >
                {isSubmitting ? "Carregando..." : "Acessar"}
              </button>
            </Form>
          )}
      </Formik>
    </>
  );
}
